import React, { useRef, useState } from 'react'
import ImageSvg from '../../components/ui/ImageSvg'
import OutfitShirtSvg from '../../components/ui/OutfitShirtSvg'
import thumb1 from '../../assets/images/thumbnails/thumbnail1.png'
import thumb2 from '../../assets/images/thumbnails/thumbnail2.png'
import thumb3 from '../../assets/images/thumbnails/thumbnail3.png'
import thumb4 from '../../assets/images/thumbnails/thumbnail4.png'
import toturialVideo from '../../assets/videos/tutorial.mp4'
import arrowSvg from '../../assets/svgs/arrowWithCircle.svg'
import { useNavigate } from 'react-router'

const EditVideo = () => {
    const inputRef = useRef()
    const navigate = useNavigate()
    const [editType, setEditType] = useState("background")
    const backgrounds = [
        {
            thumbnail: thumb1,
            id: 1
        },
        {
            thumbnail: thumb2,
            id: 2
        },
        {
            thumbnail: thumb3,
            id: 3
        },
        {
            thumbnail: thumb4,
            id: 4
        },
        {
            thumbnail: thumb4,
            id: 5
        },
        {
            thumbnail: thumb3,
            id: 6
        }
    ]

    return (
        // <div className="px-6 py-6 grid grid-cols-7 gap-4">
        <div className="px-6 py-6 flex xl:flex-row flex-col gap-4">
            <div className="col-span-1 xl:max-w-[150px] xl:min-w-[130px] w-full rounded-lg border border-gray-400 flex xl:flex-col flex-row gap-6 xl:py-6 py-3 px-6">
                <div onClick={() => setEditType("background")} className={`fle flex-col gap-3 justify-center items-center text-center [&>svg]:mx-auto [&>svg]:h-8 cursor-pointer ${editType === 'background' ? 'text-colorPrimary' : 'text-gray-600'}`}>
                    <ImageSvg />
                    <p className='text-xs font-semibold mt-2'>Add Background</p>
                </div>
                <div onClick={() => setEditType("outfit")} className={`fle flex-col gap-3 justify-center items-center text-center [&>svg]:mx-auto [&>svg]:h-8 cursor-pointer ${editType === 'outfit' ? 'text-colorPrimary' : 'text-gray-600'}`}>
                    <OutfitShirtSvg />
                    <p className='text-xs font-semibold mt-2'>Outfit</p>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col gap-4 grow">
                <div className="col-span-4 grow">
                    <div className='bg-gray-100 rounded-lg mb-6 px-3 py-3 flex justify-between items-center'>
                        <button onClick={() => navigate(-1)} className="text-gray-800 float-right leading-none outline-none focus:outline-none flex items-center gap-1">
                            <img src={arrowSvg} alt="arrow" />
                            <span className='font-bold text-sm'>Back</span>
                        </button>
                        <button type="button" className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-3 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Export</button>
                    </div>
                    <video controls className='rounded-[20px] lg:h-[405px] w-full'>
                        <source src={toturialVideo} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                    <div className='text-center mt-5 lg:block hidden'>
                        <button type="button" className="text-white bg-colorPrimary text-sm border border-colorPrimary font-semibold px-3 py-2.5 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Generate Video</button>
                    </div>
                </div>
                <div className="col-span-2 xl:max-w-[296px] lg:min-w-[296px] lg:w-[160px] w-full rounded-lg border border-gray-400 flex flex-col gap-5 py-6 xl:px-6 px-3">
                    {editType === 'background' && <div className='flex flex-col sm:flex-row lg:flex-col w-full gap-5'>
                        <div onClick={() => inputRef.current.click()} className="flex gap-2 border border-gray-400 border-dashed py-3 rounded cursor-pointer items-center justify-center w-full">
                            <p className='text-sm font-semibold text-center'>Upload an image</p>
                            <input type="file" className='sr-only' ref={inputRef} />
                        </div>
                        <div className="relative w-full">
                            <div className="absolute inset-y-1 left-3 flex justify-center items-center">
                                <svg className='z-10 w-4 text-gray-400' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4778 19.4778L17.7158 17.7158M1.85791 10.2274C1.85791 5.60504 5.60504 1.85791 10.2274 1.85791C14.8497 1.85791 18.5968 5.60504 18.5968 10.2274C18.5968 14.8497 14.8497 18.5968 10.2274 18.5968C5.60504 18.5968 1.85791 14.8497 1.85791 10.2274Z" stroke="currentColor" stroke-width="1.32149" stroke-linecap="round" stroke-linejoin="round" /></svg>
                            </div>
                            <span className='relative'>
                                <input
                                    type='text'
                                    name="search"
                                    placeholder="Search Backgrounds"
                                    className={`block w-full rounded border border-gray-100 bg-gray-100 py-3 pr-5 pl-9 text-base/2 text-sm text text-neutral-950 ring-4 ring-transparent transition placeholder:text-neutral-300 focus:border-colorPrimary focus:outline-none leading-none`}
                                />
                            </span>
                        </div>
                    </div>}
                    <h6 className='text-sm font-bold'>{editType === 'background' ? 'Collections' : 'Colors'}</h6>
                    <div className="lg:grid lg:grid-cols-2 flex gap-3 overflow-auto">
                        {backgrounds.map((bg, index) => {
                            return <img src={bg.thumbnail} key={index} alt="thumbnail" className='rounded-md h-[80px] w-[120px] object-cover object-center lg:h-[90px] lg:w-full' />
                        })}
                    </div>
                </div>
                <div className='text-center mt-1 lg:hidden block'>
                    <button type="button" className="text-white bg-colorPrimary text-sm border border-colorPrimary font-semibold px-3 py-2.5 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Generate Video</button>
                </div>
            </div>
        </div>
    )
}

export default EditVideo