import React, { useEffect, useState } from 'react'
import google from '../../assets/svgs/google.svg'
import facebook from '../../assets/svgs/facebook.svg'
import apple from '../../assets/svgs/apple.svg'
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { socialSignupLogin } from '../../redux/auth/action';

export default function ThirdPartySignin({ type }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState();
  // const { user, loginWithRedirect, loginWithPopup } = useAuth0();
  const { loginWithRedirect, loginWithPopup } = useAuth0();
  // console.log("uuuuuuuuser :", user);
  const login = useGoogleLogin({
    onSuccess: tokenResponse => setUser(tokenResponse),
  });
  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            dispatch(socialSignupLogin({
              type: "GOOGLE",
              email: res?.data?.email,
              social_token: user?.access_token,
              name: res?.data?.name,
              profile_picture: res?.data?.picture
            }))



            localStorage.setItem('profile', JSON.stringify(res.data));
            console.log(res.data)
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  const logOut = () => {
    googleLogout();
  };
  // const handleLogin = (provider) => {
  //   loginWithRedirect({
  //     screen_hint: 'signup',
  //     connection: provider // Specify the connection name for the social login provider
  //   });
  // };
  useEffect(() => {
    console.log("profile}}}}}}}}}}}}}}}}}}}}", profile)
  }, [profile])
  const handleLogin = (provider) => loginWithRedirect({
    authorizationParams: {
      connection: provider
    }
  })
  // onClick={() => loginWithRedirect({authorizationParams: {
  //   connection: "github"
  // }})}
  return (
    <div className="flex flex-col gap-3">
      <button type="button" onClick={() => login()} className="flex items-center gap-2 border w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <img src={google} alt='google' width={20} />
        {type} with Google
      </button>
      <button type="button" onClick={() => handleLogin('facebook')} className="flex items-center gap-2 border w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <img src={facebook} alt='facebook' width={20} />
        {type} with Facebook
      </button>
      {/* <button type="submit" className="flex items-center gap-2 border w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <img src={apple} alt='apple' width={20} />
        {type} with Apple
      </button> */}
    </div>
  );
}
