import { GET_USER, UPDATE_USER } from "../actonTypes"

export const getUser = () => {
    return ({
        type: GET_USER,
    })
}

export const updateUser = (data) => {
    return ({
        type: UPDATE_USER,
        payload: data
    })
}
