import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './routes';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
function Loading() {
  return (
    <div className='flex w-full h-screen justify-center items-center'>
      <div className='text-center flex flex-col justify-center items-center'>
        {/* <img className="w-1/2" src={loader} alt="" /> */}
        <h2 className='font-bold text-center text-2xl'>Soulscast is Loading......</h2>
      </div>
    </div>
  )
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="638252530287-215k7rd7jq2kr39eugomcpv41tvg6sqb.apps.googleusercontent.com">
      <Auth0Provider
        //  domain="dev-mhy6gqg54nhqs1nc.us.auth0.com"
        //  clientId="1UHyCi6IZ4zLDspUUckGjqQDfBZfgZjL"
        domain="dev-lkyjht4ket1gnxgn.us.auth0.com"
        clientId="A3kQKMEgvZfS8MV2uCk62hOkryyj1Xbh"
        authorizationParams={{
          redirect_uri: window.location.origin
        }}>
        <Provider store={store}>
          <>
            <Suspense fallback={<Loading />}>
              <ToastContainer />
              <Routers />
            </Suspense>
          </>
        </Provider>
      </Auth0Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
