import React from 'react'
import { RECORD, UPLOAD } from '../../../constants/VideoModalStepper'
import SelectLanguage from '../../../components/ui/SelectLanguage'

const UploadVideoOptions = ({ setStepType, setLanguage, language }) => {
    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto">
            <div className='flex flex-col gap-3'>
                <p className='text-gray-800 font-bold text-ba'>Upload Your Footage</p>
                <div className='bg-gray-100 border border-gray-400 p-2 rounded-lg'>
                    <p className='text-gray-600 text-sm'>For optional, most realistic results, we recommend upload a 2 minutes video recorded with the high resolution camera or smartphone. If you’re just testing the product, feel free to submit a 30 - second recording using your webcam.</p>
                </div>
                <div className="my-3">
                    <SelectLanguage setLanguage={setLanguage} />
                </div>
                {language && <div className="flex gap-3">
                    <div onClick={() => setStepType(UPLOAD)} className="h-[170px] w-1/2 cursor-pointer border border-gray-400 rounded-lg relative">
                        <div className="py-1.5 px-3 bg-gray-300 border border-gray-400 text-colorPrimary rounded-lg w-fit text-sm absolute top-[-1px] left-[-1px]">Recommended</div>
                        <div className="flex flex-col justify-center items-center h-full w-auto">
                            <p className='font-semibold text-sm text-gray-800'>Upload Footage</p>
                            <p className='text-sm text-gray-600'>Best Quality</p>
                        </div>
                    </div>
                    <div onClick={() => setStepType(RECORD)} className="h-[170px] w-1/2 cursor-pointer border border-gray-300 rounded-lg relative">
                        <div className="flex flex-col justify-center items-center h-full w-auto">
                            <p className='font-semibold text-sm text-gray-800'>Record With Webcam</p>
                            <p className='text-sm text-gray-600'>Quick Try</p>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default UploadVideoOptions