import React, { useEffect, useRef, useState } from 'react'
// import AudioItem from './AudioItem'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from 'react-slick'
// import PreviousNextSlideBtn from '../../components/ui/PreviousNextSlideBtn';
// import CreateRecordingModal from './CreateRecordingModal';
import CloneVoiceModal from './CloneVoiceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getChapter, getMetaDataCSV, getRecordings } from '../../redux/audio/action';
import VoiceSlider from './VoiceSlider';
import { uploadCSV } from '../../redux/uploadFile/action';
import CreateRecordingModal from './CreateRecordingModal';

const MyVoices = ({setExpression}) => {
    const languages = useSelector((state) => state.audioReducer?.getAudioStudio?.data)
    const recordings = useSelector((state) => state?.audioReducer?.getRecordings?.data)
    const metaDataCSV=useSelector((state) => state?.audioReducer?.getMetaDataCSV?.data)
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const chapters = useSelector((state) => state?.audioReducer?.getChapter?.data)
    const chapterLoading = useSelector((state) => state?.audioReducer?.getChapter?.loading)
    // const chapterLoading = true
    const [CSV_data, setCSV_data] = useState({
        csv: "",
        language_id: "",
        chapter_id: "",
    })
    const [filterid,setFilterId]=useState(chapters?.emotion?.[0]?.id);
    const [filter, setFilter] = useState(chapters?.emotion?.[0]?.name);
    useEffect(() => {
        if (!chapters) {
            if (id !== null) {
                dispatch(getChapter(id));
            }
        }
        setFilter(chapters?.emotion?.[0]?.name)
        setFilterId(chapters?.emotion?.[0]?.id)
        console.log("get chapters", chapters);
    }, [chapters])

    useEffect(() => {
        if (id !== null) {
            dispatch(getChapter(id));
            dispatch(getRecordings(id));
        }
        console.log("get chapters", chapters);
        console.log("get recordings", recordings);
    }, [id])
    // const handleExportCSV = () => {
    //     // Filter recordings based on the selected filter
    //     const filteredRecordings = recordings.filter(chapter => chapter?.name?.toLowerCase() === (filter && filter?.toLowerCase()));

    //     // Convert filtered recordings to CSV format
    //     const csvContent = "data:text/csv;charset=utf-8," +
    //         "prompt,audio\n" +
    //         filteredRecordings.map(chapter =>
    //             chapter.topics.map(topic =>
    //                 `"${topic.prompt.replace(/"/g, '""')}","${topic.audio}"`
    //             ).join("\n")
    //         ).join("\n");

    //     // Create a virtual link and trigger the download
    //     const encodedUri = encodeURI(csvContent);
    //     const link = document.createElement("a");
    //     link.setAttribute("href", encodedUri);
    //     link.setAttribute("download", "filtered_recordings.csv");
    //     document.body.appendChild(link);
    //     link.click();
    // };

    // useEffect(() => {
    //     setExpression(filter)
    //     if (filter) {
    //         const selectedChapter = chapters.find(chapter => chapter?.name?.toLowerCase() === (filter && filter?.toLowerCase()));

    //         console.log(selectedChapter);
    //         if (selectedChapter) {

    //             console.log(selectedChapter?.id);
    //             // Update state with chapter_id and language_id
    //             setCSV_data(prevState => ({
    //                 ...prevState,
    //                 chapter_id: selectedChapter?.id,
    //                 language_id: selectedChapter?.language_id
    //             }));
    //         }
    //     }

    // }, [filter])


    // const handleExportCSV = () => {
    //     // Filter recordings based on the selected filter
    //     console.log(CSV_data);

    //     // const filteredRecordings = metaDataCSV.filter(chapter => chapter?.name?.toLowerCase() === (filter && filter?.toLowerCase()));

    //     // Convert filtered recordings to CSV format
    //     const csvContent = "prompt,audio\n" +
    //     metaDataCSV.map(chapter =>
    //             chapter.topics.map(topic =>
    //                 `"${topic.prompt.replace(/"/g, '""')}","${topic.audio}"`
    //             ).join("\n")
    //         ).join("\n");

    //     // Create a Blob from the CSV content
    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     setCSV_data({ ...CSV_data, csv: blob });
    // };



    const getMetaData=()=>{
        dispatch(getMetaDataCSV(id))
    }

    useEffect(() => {
        if(metaDataCSV){
            handleExportCSV()
        }
      
    }, [metaDataCSV])
    
    const handleExportCSV = () => {
        // Ensure metaDataCSV is defined and not null/undefined
        if (!metaDataCSV) return;
        // Convert filtered recordings to CSV format
        const csvContent =
            metaDataCSV.map(chapter => {
                // Append chapter_id_fk to the audio filename
                const audioWithChapter = `${chapter.chapter_id_fk}/${chapter.audio}`;
                return `${audioWithChapter}|${chapter.prompt.replace(/"/g, '""')}`;
            }).join("\n");
        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // setCSV_data({ ...CSV_data, csv: blob,language_id:id });
        dispatch(uploadCSV({csv:blob,language_id:id}))
    };
    
    // useEffect(() => {
    //     if (CSV_data?.language_id && CSV_data?.csv && CSV_data?.chapter_id) {
    //         dispatch(uploadCSV(CSV_data))
           
    //     }
    // }, [CSV_data])
    return (
        <div>
            {
                chapters && chapters?.emotion.length > 0 ?
                    <>
                        <div className='mb-5'>
                            <h2 className='text-lg font-semibold'>Select Expression</h2>
                            <div className='flex flex-wrap mt-3 gap-x-2  gap-y-2 items-center'>
                                {
                                    chapterLoading?
                                    <div className='flex flex-wrap justify-between gap-x-2'>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
                                    </div>
                                    :
                                    <>
                                    {
chapters?.emotion?.map((item) =>
    <button onClick={() => {setFilter(item?.name); setFilterId(item?.id);setExpression(filter)}} className={`border border-gray-300 px-5 py-2  text-gray-500 flex flex-col items-center justify-center rounded-full border-2 hover:bg-colorPrimary hover:text-white ${filter === item?.name && " bg-colorPrimary text-white  border-colorPrimary"} gap-y-2`}>     
        {item?.name}
    </button>
)  
                                    }
                                    
                                   
                                    
                                    </>
                                  
                                     
                                }
                            </div>
                            <hr className='w-full my-2' />
                            <div className='flex flex-wrap mt-3 gap-x-2  gap-y-2 items-center'>
                                {
                                    chapterLoading?
                                    <div className='flex flex-wrap justify-between gap-x-2'>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
<div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
</div>
                                    </div>
                                    :
                                    <>
                                    {
chapters?.general?.map((item) =>
    <button onClick={() => {setFilter(item?.name);setExpression(filter)}} className={`border border-gray-300 px-5 py-2  text-gray-500 flex flex-col items-center justify-center rounded-full border-2 hover:bg-colorPrimary hover:text-white ${filter === item?.name && " bg-colorPrimary text-white  border-colorPrimary"} gap-y-2`}>     
        {item?.name}
    </button>
)  
                                    }
                                    
                                   
                                    
                                    </>
                                  
                                     
                                }
                            </div>
                        </div>

                    </> :
                    <div className="flex flex-col gap-5 text-sm  mt-8 items-center justify-center">
                        <h6 className='font-semibold'>Currently you have not any voices</h6>
                        {languages?.length<1&&<div><CreateRecordingModal /></div>}
                        
                        
                        {/* <div className="flex justify-center gap-3">
                            <div className="flex gap-1">
                                <p>Format:</p>
                                <h6 className='font-semibold'>MP3</h6>
                            </div>
                            <div className="flex gap-1">
                                <p>Duration:</p>
                                <h6 className='font-semibold'>30 Secs</h6>
                            </div>
                        </div>
                        <div className="flex justify-center gap-1">
                            <h6 className='font-semibold'>Note:</h6>
                            <p>In order to create your digital duplicate. You have to upload 21 voices of 30 seconds and 10 voice with duration of 2 minutes to 60 minutes</p>
                        </div> */}
                </div>
            }
            {recordings && recordings?.length > 0 &&
                <>
                    {!chapterLoading &&
                        recordings
                            ?.filter(chapter => chapter?.name?.toLowerCase() === (filter && filter?.toLowerCase()))
                            ?.map((chapter) => (
                                <div key={chapter.chapter_id}>
                                    {chapter?.topics?.length > 0 ? <div className="flex flex-col md:flex-row md:items-center justify-between gap-y-4 md:gap-y-0">
                                        <div>
                                            <h1 className='font-semibold text-lg'>My Voices</h1>
                                        </div>
                                        <div class="w-full  md:w-1/2 bg-gray-200 rounded-full ">
                                            {/* <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${(chapter?.completed_topics/chapter?.total_topics)*100}%` }}>{(chapter?.completed_topics/chapter?.total_topics)*100}</div> */}
                                            <div class="bg-colorPrimary text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${Math.max((chapter?.completed_topics / chapter?.total_topics) * 100, 10)}%` }}>
                                            {(chapter?.completed_topics && chapter?.total_topics) ? 
  `${((chapter?.completed_topics / chapter?.total_topics) * 100).toFixed(2)}%` : '0.00%'}
                                            </div>
                                        </div>
                                        <div className='sm:flex sm:flex-row flex flex-col'>
                                            <button onClick={() => navigate(`recording/${filter}/${filterid}`)} className='flex items-center justify-center mx-2 gap-1.5 border border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                <span>Add more voices</span>
                                            </button>
                                            {
                                                chapter?.topics?.length >= 0 ?
                                                <CloneVoiceModal handleExportCSV={handleExportCSV} getMetaData={getMetaData} />
                                                :<button className='bg-orange-500 text-sm px-2 py-1 animate-pulse  text-white rounded sm:mt-0 mt-2'>{`${500-(chapter?.topics?.length)} voices left to generate clone`}</button>
                                            }
                                            
                                            {/* <button onClick={handleExportCSV}>Export to CSV</button> */}
                                             
                                        </div>

                                    </div> :
                                        <div className="flex flex-col md:flex-row md:items-center justify-between gap-y-4 md:gap-y-0">
                                            <div>
                                                <h1 className='font-semibold text-lg'>My Voices</h1>
                                            </div>
                                            {/* <div className='sm:flex sm:flex-row flex flex-col'>
                                                <button onClick={() => navigate('recording')} className='flex items-center justify-center mx-2 gap-1.5 border bg-colorPrimary text-white border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                    <span>Add Voices</span>
                                                </button>
                                            </div> */}
                                        </div>
                                    }
                                    <div>
                                        <div className="mt-3 flex justify-between">
                                            <p className='font-semibold'>{chapter?.name}</p>
                                            {/* <p className='font-semibol text-gray-400'>16/800</p> */}
                                        </div>
                                        {chapter?.topics?.length > 0 ? (
                                            <VoiceSlider chapter={chapter} />
                                        )
                                            : <div className="flex flex-col gap-3 text-sm text-center mt-8 justify-cente items-center">
                                                <h6 className='font-semibold'>Currently, There’s no data available</h6>
                                            <div className='flex flex-col justify-center items-center'>
                                                <button onClick={() => navigate(`recording/${filter}/${filterid}`)} className='flex items-center justify-center mx-2 gap-1.5 border bg-colorPrimary text-white border-colorPrimary text-colorPrimary px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 hover:bg-colorPrimary hover:text-white '>
                                                    <span>Add Voices</span>
                                                </button>
                                            </div>
                                      
                                          
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                    }
                </>
            }
        </div>
    )
}

export default MyVoices