import React, { useEffect, useRef, useState } from 'react'
import InputField from '../../../components/ui/InputField'
import driveIcon from '../../../assets/svgs/gdrive.svg'
import arrowSvg from '../../../assets/svgs/arrowWithCircle.svg'
import { UPLOADED } from '../../../constants/VideoModalStepper'
import ButtonsVideoTypeSelect from './ButtonsVideoTypeSelect'
import { useDispatch } from 'react-redux'
import { clearVideoPrompt, setVideoPrompt } from '../../../redux/video/action'

const UploadVideo = ({ setStepType, stepType, language }) => {
    const inpFIleRef = useRef()
    const dispatch = useDispatch();
    const [dragging, setDragging] = useState(false);
    const [video, setVideo] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    const handleDragStart = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    useEffect(() => {
        console.log('Video state updated:', video);
    }, [video]);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (file.type.startsWith('video/mp4')) {
                const reader = new FileReader();
                reader.onload = () => {
                    setVideo(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Please drop a valid mp4 video file');
            }
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideo(null);
            const videoURL = URL.createObjectURL(file);
            setVideo(videoURL);
            setVideoFile(file);
            e.target.value = null;
        }
    };

    const handdleSubmit = () => {
        if (!videoFile || !language) return;

        const videoBlob = new Blob([videoFile], { type: 'video/mp4' });
        
        const formData = new File([videoBlob], "recorded-video.mp4", { type: "video/mp4" });

        // const formData = new FormData();
        // formData.append('video', videoBlob, videoFile.name);
        // const formData = new FormData();
        // formData.append('video', videoFile);
        const data = { language_id: language, video: formData }

        console.log(data, { language_id: language, video: formData });
        dispatch(setVideoPrompt(data));
        dispatch(clearVideoPrompt());
        // setStepType(UPLOADED)
    }

    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto">
            <div className="mb-5 flex gap-3 justify-center">
                <ButtonsVideoTypeSelect setStepType={setStepType} stepType={stepType} />
            </div>
            {/* <div className='bg-gray-100 border border-gray-400 p-2 rounded-lg'>
                <p className='font-bold text-sm text-center'>Upload Via Google Drive</p>
                <InputField type={"name"} logo={driveIcon} placeholder={"Paste your video URL here (up to 5GB)"} />
            </div> */}
            <div className={`dropzone ${dragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={handleDragStart}
                onDragLeave={() => setDragging(false)}
            >
                {video ? (
                    <video key={video} controls className='rounded-lg w-[320px] mx-auto my-4'>
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                ) : (
                    <div className='text-sm mt-5 mb-3 text-center'>
                        <p className='font-semibold mb-2'>drag & drop your footage here</p>
                        <p>Landscape or portrait video, mp4/mov/web format, 2-5 min, 360p-4k resolution, {"<"}2GB</p>
                        <p className='font-semibold mt-3'>Or</p>
                    </div>
                )}
            </div>
            <div className="text-center">
                <button type="button" onClick={() => inpFIleRef.current.click()} className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Browse Local Files</button>
                <input type="file" name="" id="" className='sr-only' ref={inpFIleRef} onChange={handleFileChange} accept="video/mp4" />
            </div>
            <button onClick={handdleSubmit} className="text-gray-800 float-right leading-none outline-none focus:outline-none flex items-center gap-1">
                <span className='font-bold text-sm'>Next</span>
                <img src={arrowSvg} alt="arrow" className='-scale-x-100' />
            </button>
        </div >
    )
}

export default UploadVideo