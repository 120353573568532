import { All_USER_GET, GET_USER, LOGIN, LOGOUT, OTP_VERIFICATION, RESEND_OTP, SET_STATUS_NULL, SIGNUP, SOCIAL_SIGNUP_LOGIN, TESTING_AUTH_TOKEN } from "../actonTypes"
export const login = (data) => {
    return ({
        type: LOGIN,
        payload: data
    })
}
export const allUserGet = (data) => {
    console.log('action is hitting')
    return ({
        type: All_USER_GET,
        payload: data
    })
}
export const testingAuthToken = (data) => {
    return ({
        type: TESTING_AUTH_TOKEN,
        payload: data
    })
}
export const signup = (data) => {
    return ({
        type: SIGNUP,
        payload: data
    })
}
export const socialSignupLogin = (data) => {
    return ({
        type: SOCIAL_SIGNUP_LOGIN,
        payload: data
    })
}
export const optVerification = (data) => {
    return ({
        type: OTP_VERIFICATION,
        payload: data
    })
}

export const resendOtp = (data) => {
    return ({
        type: RESEND_OTP,
        payload: data
    })
}
export const logoutLocal = () => {
    return ({
        type: LOGOUT,
    })
}
export const setStatusNull = () => {
    return ({
        type: SET_STATUS_NULL
    })
}