import React, { useRef } from 'react';
import AudioItem from './AudioItem';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreviousNextSlideBtn from '../../components/ui/PreviousNextSlideBtn';

const VoiceSlider = ({ chapter }) => {
    const sliderRef = useRef(null);
    const sliderLength = chapter?.topics?.length;
console.log(chapter?.topics?.length)
    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        arrows: false,
        rows: sliderLength <= 9 ? Math.ceil(sliderLength / 3) : 3,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                   
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    
                }
            },
        ]
    };

    return (
        <>
       
        {chapter?.topics?.length<=5&&
        <div  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 mt-4">
                {chapter?.topics?.map((audio, index) => (
                    <div>
                    <AudioItem {...audio} chapter={chapter?.chapter_id} key={index} index={index + 1} /> 
                    </div>      
                ))}
        
            </div>
}

            
       


     <div id='voice-slider' className="md:grid-rows-2 lg:grid-rows-4 2xl:grid-rows-5 gap-3 mt-4"></div>
   { chapter?.topics?.length>=5&&
    
     <Slider {...settings} ref={sliderRef}>
                 {chapter?.topics?.map((audio, index) => (
                     <AudioItem {...audio} chapter={chapter?.chapter_id} key={index} index={index + 1} />
                 ))}
             </Slider>
                }
             <div className={`mt-3 flex gap-2.5 justify-center ${sliderLength <= 9 && 'hidden'}`}>
                 <PreviousNextSlideBtn handleSlide={previous} next={false} />
                 <PreviousNextSlideBtn handleSlide={next} next={true} />
             </div>
   
   
     
 
  </>
  
    );
};

export default VoiceSlider;
