import React from 'react'

const TickCheckSvg = () => {
    return (
        <>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.833496" y="0.5" width="15" height="15" rx="7.5" stroke="currentColor" />
                <path d="M13.188 5.35464L7.18802 11.3546C7.09434 11.4478 6.96761 11.5 6.83552 11.5C6.70343 11.5 6.5767 11.4478 6.48302 11.3546L3.48302 8.35464C3.40111 8.25899 3.35831 8.13595 3.36317 8.01012C3.36803 7.88428 3.42019 7.76491 3.50924 7.67586C3.59829 7.58681 3.71766 7.53465 3.84349 7.52979C3.96933 7.52493 4.09237 7.56773 4.18802 7.64964L6.83302 10.2946L12.478 4.64464C12.5737 4.56273 12.6967 4.51993 12.8225 4.52479C12.9484 4.52965 13.0678 4.58181 13.1568 4.67086C13.2459 4.75991 13.298 4.87928 13.3029 5.00512C13.3077 5.13095 13.2649 5.25399 13.183 5.34964L13.188 5.35464Z" fill="currentColor" />
            </svg>
        </>
    )
}

export default TickCheckSvg