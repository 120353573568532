import {GET_LANGUAGE_FAILURE, GET_LANGUAGE_SUCCESS, GET_LANGUAGE, GET_CLONE_LANGUAGE, GET_CLONE_LANGUAGE_SUCCESS, GET_CLONE_LANGUAGE_FAILURE } from "../actonTypes";
const initial_state = {
    getLanguage:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    getCloneLanguage:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
}
const languageReducer = (state = initial_state, { type, payload }) => {
    console.log("payload",payload)
    switch (type) {
            case GET_LANGUAGE:
            return {
                ...state,
                getLanguage: {
                    ...state.getLanguage,
                    loading: true,
                }
            };
        case GET_LANGUAGE_SUCCESS:
            console.log("GET_USER_DETAILS_SUCCESS",payload.data)
            return {
                ...state,
                getLanguage: {
                    ...state.getLanguage,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_LANGUAGE_FAILURE:
            return {
                ...state,
                getLanguage: {
                    ...state.getLanguage,
                    loading: false,
                    error: payload,
                }
            }; 
        
            case GET_CLONE_LANGUAGE:
                return {
                    ...state,
                    getCloneLanguage: {
                        ...state.getCloneLanguage,
                        loading: true,
                    }
                };
            case GET_CLONE_LANGUAGE_SUCCESS:
                console.log("GET_USER_DETAILS_SUCCESS",payload.data)
                return {
                    ...state,
                    getCloneLanguage: {
                        ...state.getCloneLanguage,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };
            case GET_CLONE_LANGUAGE_FAILURE:
                return {
                    ...state,
                    getCloneLanguage: {
                        ...state.getCloneLanguage,
                        loading: false,
                        error: payload,
                    }
                }; 
            
            default:
                return state;
        }
}
export default languageReducer
       