import React from 'react'
import { AVATAR } from '../../../constants/VideoModalStepper'

const VideoUploaded = ({ setStepType }) => {
    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto text-center">
            <h6 className='font-semibold'>Your Video has been uploaded!</h6>
            <p className='text-sm my-3'>your instant avatar is being created and will be ready in just a few minutes. We will notify you by email when it is ready. If you have any questions regarding your instant avatar, please feel free to contact custome, avatar@dipspik.com</p>
            <button type="button" onClick={() => setStepType(AVATAR)} className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Done</button>
        </div >
    )
}

export default VideoUploaded