import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import uploadFileReducer from "./uploadFile/reducer";
import userReducer from "./user/reducer";
import languageReducer from "./language/reducer";
import audioReducer from "./audio/reducer";
import { LOGOUT } from "./actonTypes";
import videoReducer from "./video/reducer";
const appReducers = combineReducers({
    authReducer: authReducer,
    uploadFileReducer: uploadFileReducer,
    userReducer: userReducer,
    languageReducer: languageReducer,
    audioReducer: audioReducer,
    videoReducer:videoReducer
})

const reducers = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined; // Reset the state to undefined, which will reset all reducers
    }
    return appReducers(state, action);
};
export default reducers;