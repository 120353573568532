import React from 'react'

const VideoPlaySvg = () => {
    return (
        <>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_384_165)">
                    <path d="M13.75 14H2.75C2.08696 14 1.45107 13.7366 0.982233 13.2678C0.513392 12.7989 0.25 12.163 0.25 11.5L0.25 4.5C0.25 3.83696 0.513392 3.20107 0.982233 2.73223C1.45107 2.26339 2.08696 2 2.75 2H13.75C14.413 2 15.0489 2.26339 15.5178 2.73223C15.9866 3.20107 16.25 3.83696 16.25 4.5V11.5C16.25 12.163 15.9866 12.7989 15.5178 13.2678C15.0489 13.7366 14.413 14 13.75 14ZM2.75 3C2.35218 3 1.97064 3.15804 1.68934 3.43934C1.40804 3.72064 1.25 4.10218 1.25 4.5V11.5C1.25 11.8978 1.40804 12.2794 1.68934 12.5607C1.97064 12.842 2.35218 13 2.75 13H13.75C14.1478 13 14.5294 12.842 14.8107 12.5607C15.092 12.2794 15.25 11.8978 15.25 11.5V4.5C15.25 4.10218 15.092 3.72064 14.8107 3.43934C14.5294 3.15804 14.1478 3 13.75 3H2.75Z" fill="currentColor" />
                    <path d="M5.75 11C5.61739 11 5.49021 10.9473 5.39645 10.8535C5.30268 10.7598 5.25 10.6326 5.25 10.5V5.49998C5.25 5.41472 5.27181 5.33087 5.31335 5.25641C5.35489 5.18195 5.41478 5.11935 5.48733 5.07455C5.55988 5.02976 5.64268 5.00427 5.72786 5.00049C5.81304 4.99671 5.89777 5.01478 5.974 5.05298L10.974 7.55298C11.0572 7.59445 11.1272 7.6583 11.1761 7.73734C11.225 7.81639 11.251 7.90752 11.251 8.00048C11.251 8.09345 11.225 8.18457 11.1761 8.26362C11.1272 8.34267 11.0572 8.40651 10.974 8.44798L5.974 10.948C5.90439 10.9825 5.82769 11.0003 5.75 11ZM6.25 6.30898V9.69098L9.632 7.99998L6.25 6.30898Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_384_165">
                        <rect width="16" height="16" fill="white" transform="translate(0.25)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default VideoPlaySvg