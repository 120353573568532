// import React, { useEffect, useState } from 'react'
// import { AudioRecorder } from 'react-audio-voice-recorder';
// import { useSelector } from 'react-redux';
// const RecordingAudio = ({data,setData}) => {
//     const response=useSelector((state)=>state.audioReducer?.setChapterPrompt?.data)
//      const [audioBlob, setAudioBlob] = useState(null);
//     useEffect(() => {
//       console.log(response)
//       if (response) {
//         setAudioBlob(null);
//       }
//     }, [response])
//     const addAudioElement = (blob) => {
//         // const convertedBlob = new Blob([blob], { type: 'audio/mp3' });
//         setData({...data,audio:convertedBlob })
//         console.log(">>>>>>>>>>>>>>>>>>>>blob",convertedBlob);
//         // setRecording(blob);
//         const audio = document.getElementById("audio");
//         // If audio element doesn't exist, create a new one
//         if (!audio) {
//             const url = URL.createObjectURL(blob);
//             const newAudio = document.createElement('audio');
//             newAudio.src = url;
//             console.log("url.................",url)
//             newAudio.controls = true;
//             newAudio.id = "audio"; // Set an id for the audio element
//             console.log(newAudio);
//             document.getElementById("audios").appendChild(newAudio);
//         } else {
//             // Replace the existing audio source
//             audio.src = URL.createObjectURL(blob);
//         }
//     };
//     return (
//         <div className='leading-[0] mt-3 sm:mt-0 sm:ml-3 sm:w-auto'>
//             <AudioRecorder
//                 onRecordingComplete={addAudioElement}
//                 audioTrackConstraints={{
//                     noiseSuppression: true,
//                     echoCancellation: true,
//                     // autoGainControl,
//                     // channelCount,
//                     // deviceId,
//                     // groupId,
//                     // sampleRate,  
//                     // sampleSize,
//                 }}
//                 onNotAllowedOrFound={(err) => console.table(err)}
//                 downloadOnSavePress={true}
//                 downloadFileExtension="webm"
//                 mediaRecorderOptions={{
//                     mimeType: 'audio/webm',
//                     audioBitsPerSecond: 128000,
//                 }}
//             // showVisualizer={true}
//             />
//             <br />
//             {audioBlob && (
//         <audio controls>
//           <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
//           Your browser does not support the audio element.
//         </audio>
//       )}
//         </div>
//     )
// }
// export default RecordingAudio












// import React, { useState } from 'react';
// import { MediaRecorder,  ReactMediaRecorder,  useReactMediaRecorder} from 'react-media-recorder';

// const RecordingAudio = ({ data, setData }) => {
//   const [audioBlob, setAudioBlob] = useState(null);

//   return (
//     <div>
//     <ReactMediaRecorder
//         audio
//         render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
//             console.log("start recordings: ",startRecording, status, mediaBlobUrl);
//             return <div className='mx-auto text-center w-[320px]'>
//                 {/* <p>{status}</p> */}
//                 {status !== 'idle' && <div className='relative'>
//                     <audio src={mediaBlobUrl} controls className='mx-auto rounded-lg' />
//                     {status === 'stopped' && <div onClick={() => clearBlobUrl()} className="absolute text-white top-0 right-0 p-2.5 cursor-pointer">
//                         {/* <DeleteIconSvg /> */}
//                     </div>}
//                 </div>
//                 }
//                 <div className="flex gap-3 justify-center my-4">
//                     <button onClick={startRecording} className={`text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status === 'recording' && 'opacity-50 cursor-default'} ${mediaBlobUrl ? 'text-colorPrimary bg-white hover:text-white hover:bg-colorPrimary' : 'text-white bg-colorPrimary'}`}>{mediaBlobUrl ? 'Record Again' : 'Start Recording'}</button>
//                     {status === 'recording' && <button onClick={stopRecording} className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status !== 'recording' && 'opacity-50 cursor-default'}`}>Stop Recording</button>}
//                     {mediaBlobUrl && <button  className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status === 'recording' && 'opacity-50 cursor-default'}`}>Submit</button>}
//                 </div>
//             </div>
//         }}
//     />
// </div>
//   );
// };

// export default RecordingAudio;










// import React, { useState } from 'react';
// import { ReactMediaRecorder } from 'react-media-recorder';

// const AudioRecorder = ({data,setData}) => {
//   const [blob, setBlob] = useState(null);
//   const [blobUrl, setBlobUrl] = useState(null);

//   return (
//     <div>
//       <ReactMediaRecorder
//         audio
//         onStop={(blobUrl, blob) => {
//             setData({...data,audio:blob})
//             console.log("blob------------------------->>>>>>>>>>>>>>>>>>>>>>>>>",blob);
//           setBlobUrl(blobUrl);
//         }}
//         render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
//           return (
//             <div className='mx-auto text-center w-[320px]'>
//               {status !== 'idle' && (
//                 <div className='relative'>
//                   <audio src={blobUrl} controls className='mx-auto rounded-lg' />
//                   {status === 'stopped' && (
//                     <div
//                       onClick={() => {
//                         clearBlobUrl();
//                         setBlob(null);
//                         setBlobUrl(null);
//                       }}
//                       className='absolute text-white top-0 right-0 p-2.5 cursor-pointer'
//                     >
//                       {/* <DeleteIconSvg /> */}
//                     </div>
//                   )}
//                 </div>
//               )}
//               <div className='flex gap-3 justify-center my-4'>
//                 <button
//                   onClick={startRecording}
//                   className={`text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status === 'recording' && 'opacity-50 cursor-default'} ${
//                     blobUrl ? 'text-colorPrimary bg-white hover:text-white hover:bg-colorPrimary' : 'text-white bg-colorPrimary'
//                   }`}
//                 >
//                   {blobUrl ? 'Record Again' : 'Start Recording'}
//                 </button>
//                 {status === 'recording' && (
//                   <button
//                     onClick={stopRecording}
//                     className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${
//                       status !== 'recording' && 'opacity-50 cursor-default'
//                     }`}
//                   >
//                     Stop Recording
//                   </button>
//                 )}
//                 {blobUrl && (
//                   <button
//                     className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${
//                       status === 'recording' && 'opacity-50 cursor-default'
//                     }`}
//                   >
//                     Submit
//                   </button>
//                 )}
//               </div>
//             </div>
//           );
//         }}
//       />
//       {blob && (
//         <div>
//           <h3>Blob Properties:</h3>
//           <p>Size: {blob.size} bytes</p>
//           <p>Type: {blob.type}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;
























// import React, { useState, useRef } from 'react';
// import Recorder from 'recorder-js';

// let gumStream = null;
// let audioContext = null;

// function RecordingAudio({ data, setData }) {
//   const [isRecording, setIsRecording] = useState(false);
//   const audioContextRef = useRef(null);
//   const recorderRef = useRef(null);

//   const startRecording = () => {
//     const constraints = {
//       audio: true,
//       video: false
//     };

//     audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//     console.log("sample rate: " + audioContextRef.current.sampleRate);

//     navigator.mediaDevices.getUserMedia(constraints)
//       .then((stream) => {
//         console.log("initializing Recorder.js ...");

//         gumStream = stream;
//         const input = audioContextRef.current.createMediaStreamSource(stream);

//         recorderRef.current = new Recorder(audioContextRef.current, {
//           numChannels: 1
//         });

//         recorderRef.current.init(stream);
//         recorderRef.current.start().then(() => {
//           setIsRecording(true);
//           console.log("Recording started");
//         });
//       })
//       .catch((err) => {
//         console.error("getUserMedia() failed: ", err);
//       });
//   };

//   const stopRecording = () => {
//     console.log("stopButton clicked");

//     if (recorderRef.current) {
//       recorderRef.current.stop()
//         .then(({ blob }) => {
//           console.log("Recording stopped");
//           gumStream.getAudioTracks()[0].stop();
//           onStop(blob);
//           setIsRecording(false);
//         })
//         .catch((err) => {
//           console.error("Error stopping recording: ", err);
//         });
//     }
//   };

//   const onStop = (blob) => {
//     console.log("uploading...");
//     setData({ ...data, audio: blob });

//     let formData = new FormData();
    
// console.log(blob)
//     console.log(formData.append('wavfile', blob, "recording.wav"));

//     // Uncomment and adjust the following lines to upload the file
//     // const config = {
//     //   headers: { 'Content-Type': 'multipart/form-data' }
//     // };
//     //
//     // axios.post('http://localhost:8080/asr/', formData, config)
//     //   .then(response => {
//     //     console.log("File uploaded successfully", response.data);
//     //   })
//     //   .catch(error => {
//     //     console.error("Error uploading file", error);
//     //   });
//   };

//   return (
//     <div className="flex justify-between gap-2">
//       <button onClick={startRecording} type="button" disabled={isRecording}>
//         Start
//       </button>
//       <button onClick={stopRecording} type="button" disabled={!isRecording}>
//         Stop
//       </button>
//     </div>
//   );
// }

// export default RecordingAudio;




// import React, { useState, useRef } from 'react';
// import Recorder from 'recorder-js';

// let gumStream = null;
// let audioContext = null;

// function RecordingAudio({ data, setData }) {
//   const [isRecording, setIsRecording] = useState(false);
//   const [blobUrl, setBlobUrl] = useState(null); // State to store the blob URL
//   const audioContextRef = useRef(null);
//   const recorderRef = useRef(null);

//   const startRecording = () => {
//     const constraints = {
//       audio: true,
//       video: false
//     };

//     audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//     console.log("sample rate: " + audioContextRef.current.sampleRate);

//     navigator.mediaDevices.getUserMedia(constraints)
//       .then((stream) => {
//         console.log("initializing Recorder.js ...");

//         gumStream = stream;
//         const input = audioContextRef.current.createMediaStreamSource(stream);

//         recorderRef.current = new Recorder(audioContextRef.current, {
//           numChannels: 1
//         });

//         recorderRef.current.init(stream);
//         recorderRef.current.start().then(() => {
//           setIsRecording(true);
//           console.log("Recording started");
//         });
//       })
//       .catch((err) => {
//         console.error("getUserMedia() failed: ", err);
//       });
//   };

//   const stopRecording = () => {
//     console.log("stopButton clicked");

//     if (recorderRef.current) {
//       recorderRef.current.stop()
//         .then(({ blob }) => {
//           console.log("Recording stopped");
//           gumStream.getAudioTracks()[0].stop();
//           onStop(blob);
//           setIsRecording(false);
//         })
//         .catch((err) => {
//           console.error("Error stopping recording: ", err);
//         });
//     }
//   };

//   const onStop = (blob) => {
//     console.log("uploading...");
//     setData({ ...data, audio: blob });

//     let formData = new FormData();
    
//     console.log(blob);
//     setData({...data,audio:blob});
//     console.log(formData.append('wavfile', blob, "recording.wav"));

//     // Create a URL for the blob and set it in the state
//     const url = URL.createObjectURL(blob);
//     setBlobUrl(url);
//   };

//   return (
//     <div className="flex justify-between gap-2">
//       <button onClick={startRecording} type="button" disabled={isRecording}>
//         Start
//       </button>
//       <button onClick={stopRecording} type="button" disabled={!isRecording}>
//         Stop
//       </button>
//       {blobUrl && (
//         <a href={blobUrl} download="recording.wav">
//           Download Recording
//         </a>

        

//       )} 
//     </div>
//   );
// }

// export default RecordingAudio;





import React, { useState, useRef, useEffect } from 'react';
import Recorder from 'recorder-js';

let gumStream = null;
let audioContext = null;

function RecordingAudio({ data, setData, audioUrl, setAudioUrl }) {
  const [isRecording, setIsRecording] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null); // State to store the blob URL
  const [recordingTime, setRecordingTime] = useState(0); // State to track recording time
  const audioContextRef = useRef(null);
  const recorderRef = useRef(null);
  const intervalRef = useRef(null); // Ref to store the interval ID

  const startRecording = () => {
    const constraints = {
      audio: true,
      video: false
    };

    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    console.log("sample rate: " + audioContextRef.current.sampleRate);

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        console.log("initializing Recorder.js ...");

        gumStream = stream;
        const input = audioContextRef.current.createMediaStreamSource(stream);

        recorderRef.current = new Recorder(audioContextRef.current, {
          numChannels: 1
        });

        recorderRef.current.init(stream);
        recorderRef.current.start().then(() => {
          setIsRecording(true);
          console.log("Recording started");

          // Start the timer
          intervalRef.current = setInterval(() => {
            setRecordingTime((prevTime) => prevTime + 1);
          }, 1000);
        });
      })
      .catch((err) => {
        console.error("getUserMedia() failed: ", err);
      });
  };

  const stopRecording = () => {
    console.log("stopButton clicked");

    if (recorderRef.current) {
      recorderRef.current.stop()
        .then(({ blob }) => {
          console.log("Recording stopped");
          gumStream.getAudioTracks()[0].stop();
          onStop(blob);
          setIsRecording(false);

          // Stop the timer
          clearInterval(intervalRef.current);
          setRecordingTime(0);
        })
        .catch((err) => {
          console.error("Error stopping recording: ", err);
        });
    }
  };

  const onStop = (blob) => {
    console.log("uploading...");
    setData({ ...data, audio: blob });

    let formData = new FormData();
    
    console.log(blob);
    setData({ ...data, audio: blob });
    console.log(formData.append('wavfile', blob, "recording.wav"));

    // Create a URL for the blob and set it in the state
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
    setBlobUrl(url);
  };

  return (
    <div className="flex justify-between gap-2 sm:ml-5">
      {
        !isRecording ?
          <button className='bg-green-500 rounded-full p-2 mt-4 md:mt-4 lg:mt-0' onClick={startRecording} type="button" disabled={isRecording}>
            <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M5 8a1 1 0 0 1 1 1v3a4.006 4.006 0 0 0 4 4h4a4.006 4.006 0 0 0 4-4V9a1 1 0 1 1 2 0v3.001A6.006 6.006 0 0 1 14.001 18H13v2h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-2H9.999A6.006 6.006 0 0 1 4 12.001V9a1 1 0 0 1 1-1Z" clipRule="evenodd" />
              <path d="M7 6a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v5a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4V6Z" />
            </svg>
          </button> :
          <button className='bg-red-500 rounded-full px-4 py-2 flex text-white gap-1 mt-4 md:mt-4 lg:mt-0 items-center' onClick={stopRecording} type="button" disabled={!isRecording}>
            <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M7 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7Z" />
            </svg>
            <p className='text-xs text-nowrap'>
            Stop {recordingTime} seconds
            </p>
           
          </button>
      }
    </div>
  );
}

export default RecordingAudio;





