import { UPLOAD_AUDIO, UPLOAD_CSV, UPLOAD_FILE } from "../actonTypes"
export const uploadFile = (data) => {
    console.log('uploadFile::::::::::::::::::.............""""""""' , data);
    return ({
        type: UPLOAD_FILE,
        payload: data
    })
}
export const uploadAudio = (data) => {
    return ({
        type: UPLOAD_AUDIO,
        payload: data
    })
}

export const uploadCSV = (data) => {
    return ({
        type: UPLOAD_CSV,
        payload: data
    })
}

