import React from 'react'
import LearningVideoItem from './LearningVideoItem'
import thumb1 from '../../assets/images/thumbnails/thumbnail1.png'
import thumb2 from '../../assets/images/thumbnails/thumbnail2.png'
import thumb3 from '../../assets/images/thumbnails/thumbnail3.png'
import thumb4 from '../../assets/images/thumbnails/thumbnail4.png'

const LearningGuide = () => {
    const videos = [
        {
            thumbnail: thumb1,
            title: "Audio Assests"
        },
        {
            thumbnail: thumb2,
            title: "Expressions"
        },
        {
            thumbnail: thumb3,
            title: "Videos"
        },
        {
            thumbnail: thumb4,
            title: "Avatars"
        },
    ]
    return (
        <div>
            <h2 className='text-lg font-semibold'>Dipspik Learning Guide</h2>
            {/* <p className='text-gray-600 text-sm font-normal'>Lorem ipsum dolor sit amet consectetur. Vel tristique enim morbi gravida facilisi congue senectus.</p> */}
            <div className="flx gap-5 my-5 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                {videos?.map((v, index) => (
                    <LearningVideoItem {...v} key={index} />
                ))}
            </div>
        </div>
    )
}

export default LearningGuide