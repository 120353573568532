import React, { useEffect, useRef, useCallback, useState } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import ButtonsVideoTypeSelect from './ButtonsVideoTypeSelect';
import SelectLanguage from '../../../components/ui/SelectLanguage';
import DeleteIconSvg from '../../../components/ui/DeleteIconSvg';
import { UPLOADED } from '../../../constants/VideoModalStepper';
import { useDispatch, useSelector } from 'react-redux';
import { clearVideoPrompt, setVideoPrompt } from '../../../redux/video/action';

const RecordVideo = ({ setStepType, stepType, language }) => {
    const dispatch = useDispatch();
    const chapters = useSelector((state) => state?.audioReducer?.getChapter?.data);
    const loading = useSelector((state) => state?.videoReducer?.getVideoPrompt?.loading);
    const [data, setData] = useState({
        language_id: "",
        video: "",
    });
    const durationRef = useRef(0);
    const intervalIdRef = useRef(null);
    const durationDisplayRef = useRef(null);

    useEffect(() => {
        if (language) {
            setData({
                ...data,
                language_id: language,
            });
        }
    }, [language]);

    const setVideo = (file) => {
        setData({ ...data, video: file });
        console.log('Video file set:', file);
    };

    const uploadVideo = () => {
        dispatch(setVideoPrompt(data));
        dispatch(clearVideoPrompt());
    };

    const startDurationCounter = () => {
        intervalIdRef.current = setInterval(() => {
            durationRef.current += 1;
            if (durationDisplayRef.current) {
                durationDisplayRef.current.innerText = formatDuration(durationRef.current);
            }
        }, 1000);
    };

    const stopDurationCounter = () => {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
    };

    const formatDuration = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    useEffect(() => {
        return () => clearInterval(intervalIdRef.current);
    }, []);

    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto">
            <ButtonsVideoTypeSelect setStepType={setStepType} stepType={stepType} />
            <p className='text-center text-sm my-3'>Please record a quick video reading the following script</p>
            {
                language &&
                <div className='mt-5'>
                    <ReactMediaRecorder
                        video={{
                            mimeType: "video/mp4"
                        }}
                        render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream }) => {
                            const handleStartRecording = () => {
                                durationRef.current=0
                                startRecording();
                                startDurationCounter();
                            };

                            const handleStopRecording = async () => {
                                stopRecording();
                                stopDurationCounter();
                                const blob = await fetch(mediaBlobUrl).then(r => r.blob());
                                const file = new File([blob], "recorded-video.mp4", { type: "video/mp4" });
                                setVideo(file);
                            };

                            const handleSubmit = async () => {
                                await uploadVideo();
                                // setStepType(UPLOADED);
                            };

                            return <div className='mx-auto text-center w-[320px]'>
                                <video
                                    ref={(videoElement) => {
                                        if (videoElement && previewStream) {
                                            videoElement.srcObject = previewStream;
                                            videoElement.play().catch(error => console.log(error));
                                        }
                                    }}
                                    className='mx-auto rounded-lg'
                                />
                                {status === 'stopped' && mediaBlobUrl && <div className='relative'>
                                    <video src={mediaBlobUrl} controls className='mx-auto rounded-lg' />
                                    {status === 'stopped' && <div onClick={() => clearBlobUrl()} className="absolute text-white top-0 right-0 p-2.5 cursor-pointer">
                                        <DeleteIconSvg />
                                    </div>}
                                </div>}
                                <div className="flex gap-3 justify-center my-4">
                                    <button onClick={handleStartRecording} className={`text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status === 'recording' && 'opacity-50 cursor-default'} ${mediaBlobUrl ? 'text-colorPrimary bg-white hover:text-white hover:bg-colorPrimary' : 'text-white bg-colorPrimary'}`}>{mediaBlobUrl ? 'Record Again' : 'Start Recording'}</button>
                                    
                                    {status === 'recording' && <button onClick={handleStopRecording} className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status !== 'recording' && 'opacity-50 cursor-default'}`}> <span ref={durationDisplayRef}>0:00</span> Stop Recording</button>}
                                    {mediaBlobUrl && <>
                                        <button onClick={handleSubmit} className={`text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-4 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${status === 'recording' && 'opacity-50 cursor-default'}`}>Submit</button>
                                    </>}
                                </div>
                            </div>
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default RecordVideo;
