import { call, put, takeLatest } from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from "react-toastify";
import { CREATE_AUDIO_STUDIO, CREATE_AUDIO_STUDIO_FAILURE, CREATE_AUDIO_STUDIO_SUCCESS, DELETE_GENERATED_VOICE, DELETE_GENERATED_VOICE_FAILURE, DELETE_GENERATED_VOICE_SUCCESS, GENERATE_VOICE_CLONE, GENERATE_VOICE_CLONE_FAILURE, GENERATE_VOICE_CLONE_SUCCESS, GET_AUDIO_STUDIO, GET_AUDIO_STUDIO_FAILURE, GET_AUDIO_STUDIO_SUCCESS, GET_CHAPTER, GET_CHAPTER_FAILURE, GET_CHAPTER_PROMPT, GET_CHAPTER_PROMPT_FAILURE, GET_CHAPTER_PROMPT_SUCCESS, GET_CHAPTER_SUCCESS, GET_GENERATED_VOICES, GET_GENERATED_VOICES_FAILURE, GET_GENERATED_VOICES_SUCCESS, GET_METADATA_CSV, GET_METADATA_CSV_FAILURE, GET_METADATA_CSV_SUCCESS, GET_RECORDINGS, GET_RECORDINGS_FAILURE, GET_RECORDINGS_SUCCESS, SET_CHAPTER_PROMPT, SET_CHAPTER_PROMPT_FAILURE, SET_CHAPTER_PROMPT_MESSAGE, SET_CHAPTER_PROMPT_SUCCESS, TEXT_TO_SPEECH, TEXT_TO_SPEECH_FAILURE, TEXT_TO_SPEECH_SUCCESS } from "../actonTypes";
function* watcherCreateAudioStudio(data) {
    let url = '/Recording_studio_controller/recording_create';
    const Data = yield call(callApi, url, 'POST', data.payload, true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: CREATE_AUDIO_STUDIO_SUCCESS, payload: Data.data });
        yield put({ type: GET_AUDIO_STUDIO });
        toast.success(Data.data.message)
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: CREATE_AUDIO_STUDIO_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherGetChapter(data) {
    let url = `/Chapter_controller/chapterGet?language_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_CHAPTER_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_CHAPTER_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherGetAudioStudio() {
    let url = '/Recording_studio_controller/recordingLanguageGet';
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)

    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_AUDIO_STUDIO_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_AUDIO_STUDIO_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherGetChapterPrompt(data) {
    let url = `/Chapter_controller/chapterPromptGet?chapter_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_CHAPTER_PROMPT_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_CHAPTER_PROMPT_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherSetChapterPrompt(data) {
    // console.log(data.payload);
    const file = new File([data.payload.audio], 'recorded_audio.wav', { type: 'audio/wav' });
    console.log(file)
    try {
        let formData = new FormData();
        formData.append('language_id', data.payload.language_id);
        formData.append('chapter_id', data.payload.chapter_id);
        formData.append('topic_id', data.payload.topic_id);
        formData.append('prompt', data.payload.prompt); // Assuming data.prompt is the text prompt
        formData.append('audio', file); // Assuming data.audio is the audio file
        const url = '/Chapter_controller/postPrompt';
        const response = yield call(callApi, url, 'POST', formData, true);
        console.log("Response:", response.data);
        yield put({type:SET_CHAPTER_PROMPT_MESSAGE})
        if (response.status === 200) {
            
            yield put({ type: SET_CHAPTER_PROMPT_SUCCESS, payload: response.data });
        } else {
            yield put({ type: SET_CHAPTER_PROMPT_FAILURE, payload: response.data.error });
            toast.error(response.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        yield put({ type: SET_CHAPTER_PROMPT_FAILURE, payload: error.message });
        toast.error("An error occurred while setting chapter prompt.");
    }
}


function* watcherGetRecordings(data) {
    let url = `/Recording_studio_controller/recordingStudioGet?language_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_RECORDINGS_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_RECORDINGS_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherGetMetaDataCSV(data) {
    let url = `/Recording_studio_controller/metadata_csv?language_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_METADATA_CSV_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_METADATA_CSV_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherGetGeneratedVoices(data) {
    let url = `/Recording_studio_controller/getGenratedVoices?language_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_GENERATED_VOICES_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_GENERATED_VOICES_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}

function* watcherDeleteGeneratedVoices(data) {
    console.log(data)
    let url = `/Recording_studio_controller/deleteGenratedVoices?language_id=${data.payload.language_id}&id=${data.payload.id}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: DELETE_GENERATED_VOICE_SUCCESS, payload: Data.data });
        yield put({type:GET_GENERATED_VOICES,payload:data?.payload?.language_id})
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: DELETE_GENERATED_VOICE_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}


function* watcherGenerateVoiceClone(data) {
    let url = '/Recording_studio_controller/recording_create';
    const Data = yield call(callApi, url, 'POST', data.payload, true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GENERATE_VOICE_CLONE_SUCCESS, payload: Data.data });
        toast.success(Data.data.message)
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GENERATE_VOICE_CLONE_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}


function* watcherTextToSpeech(data) {
    let url = '/Recording_studio_controller/genrateVoice';
    const Data = yield call(callApi, url, 'POST', data.payload, true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: TEXT_TO_SPEECH_SUCCESS, payload: Data.data });
        yield put({type:GET_GENERATED_VOICES,payload:data?.payload?.language_id})
       
        toast.success(Data.data.message)
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: TEXT_TO_SPEECH_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
export default function* watchAudio() {
    yield takeLatest(CREATE_AUDIO_STUDIO, watcherCreateAudioStudio)
    yield takeLatest(GET_CHAPTER, watcherGetChapter)
    yield takeLatest(GET_AUDIO_STUDIO, watcherGetAudioStudio)
    yield takeLatest(GET_CHAPTER_PROMPT, watcherGetChapterPrompt)
    yield takeLatest(GET_RECORDINGS, watcherGetRecordings)
    yield takeLatest(SET_CHAPTER_PROMPT, watcherSetChapterPrompt)
    yield takeLatest(GET_METADATA_CSV, watcherGetMetaDataCSV)
    yield takeLatest(GET_GENERATED_VOICES, watcherGetGeneratedVoices)
    yield takeLatest(DELETE_GENERATED_VOICE, watcherDeleteGeneratedVoices)

    yield takeLatest(GENERATE_VOICE_CLONE,watcherGenerateVoiceClone)
    yield takeLatest(TEXT_TO_SPEECH,watcherTextToSpeech)

}