import { call, put, takeLatest } from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from "react-toastify";
import { CREATE_AUDIO_STUDIO, CREATE_AUDIO_STUDIO_FAILURE, CREATE_AUDIO_STUDIO_SUCCESS, GET_AUDIO_STUDIO, GET_AUDIO_STUDIO_FAILURE, GET_AUDIO_STUDIO_SUCCESS, GET_CHAPTER, GET_CHAPTER_FAILURE, GET_CHAPTER_PROMPT, GET_CHAPTER_PROMPT_FAILURE, GET_CHAPTER_PROMPT_SUCCESS, GET_CHAPTER_SUCCESS, GET_RECORDINGS, GET_RECORDINGS_FAILURE, GET_RECORDINGS_SUCCESS, GET_VIDEO_PROMPT, GET_VIDEO_PROMPT_FAILURE, GET_VIDEO_PROMPT_SUCCESS, SET_CHAPTER_PROMPT, SET_CHAPTER_PROMPT_FAILURE, SET_CHAPTER_PROMPT_SUCCESS, SET_VIDEO_PROMPT, SET_VIDEO_PROMPT_FAILURE, SET_VIDEO_PROMPT_SUCCESS } from "../actonTypes";
// function* watcherCreateAudioStudio(data) {
//     let url = '/Recording_studio_controller/recording_create';
//     const Data = yield call(callApi, url, 'POST', data.payload, true);
//     // console.log(Data.data)
//     console.log("..................................", Data.data)
//     if (Data.status === 200) {
//         console.log("Data.data", Data.data)
//         yield put({ type: CREATE_AUDIO_STUDIO_SUCCESS, payload: Data.data });
//         yield put({ type: GET_AUDIO_STUDIO });
//         toast.success(Data.data.message)
//         console.log("..................................", Data.data)
//     }
//     else {
//         yield put({ type: CREATE_AUDIO_STUDIO_FAILURE, payload: Data.data.error })
//         toast.error(Data.data.message)
//     }
// }
// function* watcherGetChapter(data) {
//     let url = `/Chapter_controller/chapterGet?language_id=${data.payload}`;
//     const Data = yield call(callApi, url, 'GET', '', true);
//     // console.log(Data.data)
//     console.log("..................................", Data.data)
//     if (Data.status === 200) {
//         console.log("Data.data", Data.data)
//         yield put({ type: GET_CHAPTER_SUCCESS, payload: Data.data });
//         console.log("..................................", Data.data)
//     }
//     else {
//         yield put({ type: GET_CHAPTER_FAILURE, payload: Data.data.error })
//         toast.error(Data.data.message)
//     }
// }
// function* watcherGetAudioStudio() {
//     let url = '/Recording_studio_controller/recordingLanguageGet';
//     const Data = yield call(callApi, url, 'GET', '', true);
//     // console.log(Data.data)
//     console.log("..................................", Data.data)
//     if (Data.status === 200) {
//         console.log("Data.data", Data.data)
//         yield put({ type: GET_AUDIO_STUDIO_SUCCESS, payload: Data.data });
//         console.log("..................................", Data.data)
//     }
//     else {
//         yield put({ type: GET_AUDIO_STUDIO_FAILURE, payload: Data.data.error })
//         toast.error(Data.data.message)
//     }
// }
function* watcherGetVideoPrompt(data) {
    let url = `/Recording_video_controller/chapterPromptGet?chapter_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_VIDEO_PROMPT_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_VIDEO_PROMPT_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
function* watcherSetVideoPrompt(data) {

    try {
        let formData = new FormData();
        formData.append('language_id', data.payload.language_id);
        formData.append('chapter_id', data.payload.chapter_id);
        formData.append('topic_id', data.payload.topic_id);
        formData.append('prompt', data.payload.prompt); // Assuming data.prompt is the text prompt
        formData.append('video', data.payload.video); // Assuming data.audio is the audio file
        const url = '/Recording_video_controller/postPrompt';
        const response = yield call(callApi, url, 'POST', formData, true);
        console.log("Response:", response.data);
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: SET_VIDEO_PROMPT_SUCCESS, payload: response.data });
            // yield put({ type: GET_CHAPTER_PROMPT, payload: data?.payload?.chapter_id });
        } else {
            yield put({ type: SET_VIDEO_PROMPT_FAILURE, payload: response.data.error });
            toast.error(response.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        yield put({ type: SET_VIDEO_PROMPT_FAILURE, payload: error.message });
        toast.error("An error occurred while setting chapter prompt.");
    }
}


function* watcherGetRecordings(data) {
    let url = `/Recording_studio_controller/recordingStudioGet?language_id=${data.payload}`;
    const Data = yield call(callApi, url, 'GET', '', true);
    // console.log(Data.data)
    console.log("..................................", Data.data)
    if (Data.status === 200) {
        console.log("Data.data", Data.data)
        yield put({ type: GET_RECORDINGS_SUCCESS, payload: Data.data });
        console.log("..................................", Data.data)
    }
    else {
        yield put({ type: GET_RECORDINGS_FAILURE, payload: Data.data.error })
        toast.error(Data.data.message)
    }
}
export default function* watchVideo() {
    // yield takeLatest(CREATE_AUDIO_STUDIO, watcherCreateAudioStudio)
    // yield takeLatest(GET_CHAPTER, watcherGetChapter)
    // yield takeLatest(GET_AUDIO_STUDIO, watcherGetAudioStudio)
    yield takeLatest(GET_VIDEO_PROMPT, watcherGetVideoPrompt)
    yield takeLatest(SET_VIDEO_PROMPT, watcherSetVideoPrompt)

    // yield takeLatest(GET_RECORDINGS, watcherGetRecordings)
    // yield takeLatest(SET_CHAPTER_PROMPT, watcherSetChapterPrompt)
}