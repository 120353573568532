import React from 'react'

const ImageSvg = () => {
    return (
        <>
            <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 22C1.23478 22 0.98043 22.1054 0.792893 22.2929C0.605357 22.4804 0.5 22.7348 0.5 23V31C0.5 33.7527 2.7473 36 5.5 36H13.5C13.7652 36 14.0196 35.8946 14.2071 35.7071C14.3946 35.5196 14.5 35.2652 14.5 35C14.5 34.7348 14.3946 34.4804 14.2071 34.2929C14.0196 34.1054 13.7652 34 13.5 34H5.5C3.82071 34 2.5 32.6793 2.5 31V23C2.5 22.7348 2.39464 22.4804 2.20711 22.2929C2.01957 22.1054 1.76522 22 1.5 22ZM35.5 22C35.2348 22 34.9804 22.1054 34.7929 22.2929C34.6054 22.4804 34.5 22.7348 34.5 23V31C34.5 32.6793 33.1793 34 31.5 34H23.5C23.2348 34 22.9804 34.1054 22.7929 34.2929C22.6054 34.4804 22.5 34.7348 22.5 35C22.5 35.2652 22.6054 35.5196 22.7929 35.7071C22.9804 35.8946 23.2348 36 23.5 36H31.5C34.2527 36 36.5 33.7527 36.5 31V23C36.5 22.7348 36.3946 22.4804 36.2071 22.2929C36.0196 22.1054 35.7652 22 35.5 22ZM5.5 0C2.7473 0 0.5 2.2473 0.5 5V13C0.5 13.2652 0.605357 13.5196 0.792893 13.7071C0.98043 13.8946 1.23478 14 1.5 14C1.76522 14 2.01957 13.8946 2.20711 13.7071C2.39464 13.5196 2.5 13.2652 2.5 13V5C2.5 3.32071 3.82071 2 5.5 2H13.5C13.7652 2 14.0196 1.89464 14.2071 1.70711C14.3946 1.51957 14.5 1.26522 14.5 1C14.5 0.734784 14.3946 0.48043 14.2071 0.292893C14.0196 0.105357 13.7652 0 13.5 0H5.5ZM23.5 0C23.2348 0 22.9804 0.105357 22.7929 0.292893C22.6054 0.48043 22.5 0.734784 22.5 1C22.5 1.26522 22.6054 1.51957 22.7929 1.70711C22.9804 1.89464 23.2348 2 23.5 2H31.5C33.1793 2 34.5 3.32071 34.5 5V13C34.5 13.2652 34.6054 13.5196 34.7929 13.7071C34.9804 13.8946 35.2348 14 35.5 14C35.7652 14 36.0196 13.8946 36.2071 13.7071C36.3946 13.5196 36.5 13.2652 36.5 13V5C36.5 2.2473 34.2527 0 31.5 0H23.5Z" fill="currentColor" />
                <path d="M14.1914 16C13.5164 16.0102 12.8444 16.3374 12.4726 16.9766L6.7773 26.7695C5.96775 28.1612 7.0267 30 8.63668 30H28.3789C29.9866 30 31.0364 28.1491 30.2109 26.7695L25.6328 19.1211C24.8718 17.8493 22.9533 17.8009 22.1328 19.0352L19.6953 22.707C19.637 22.7946 19.5946 22.7924 19.539 22.703L15.9375 16.9258C15.5463 16.2982 14.8664 15.9897 14.1914 16ZM14.2031 17.9805C14.2334 17.9283 14.2063 17.9292 14.2382 17.9805L17.8437 23.7617C18.6283 25.0205 20.5381 25.0477 21.3593 23.8125L23.7968 20.1445C23.8476 20.0682 23.8709 20.0698 23.9179 20.1485L28.4961 27.797C28.5751 27.929 28.5328 28 28.3789 28H8.63668C8.47224 28 8.42509 27.9156 8.50777 27.7734L14.2031 17.9805ZM27.5 6C25.8549 6 24.5 7.35499 24.5 9C24.5 10.645 25.8549 12 27.5 12C29.145 12 30.5 10.645 30.5 9C30.5 7.35499 29.145 6 27.5 6ZM27.5 8C28.0641 8 28.5 8.43587 28.5 9C28.5 9.56413 28.0641 10 27.5 10C26.9358 10 26.5 9.56413 26.5 9C26.5 8.43587 26.9358 8 27.5 8Z" fill="currentColor" />
            </svg>
        </>
    )
}

export default ImageSvg