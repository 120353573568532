import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SelectLanguage from '../../components/ui/SelectLanguage';
import { getLanguage } from '../../redux/language/action';
import ReactSelect from 'react-select';
import { createAudioStudio } from '../../redux/audio/action';

const CreateRecordingModal = () => {
    const languages = useSelector((state) => state.audioReducer?.getAudioStudio?.data)
    const [options, setOptions] = useState([]);
    const dispatch=useDispatch()
    const language=useSelector((state)=>state?.languageReducer?.getLanguage?.data)
    const studio=useSelector((state)=>state?.audioReducer?.createAudioStudio) 
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
      if(studio&&selectedOption){
        navigate(`/audio/${selectedOption?.value}/recording`)
      }
    }, [studio])
    
    useEffect(() => {
        if(!language||language===""){
            dispatch(getLanguage())
        }
        else{
            console.log(language)
            const newOptions = language?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setOptions(newOptions);
        }
    }, [language])
  
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Selected ID: ${selectedOption?.value}`);
      };
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    }
    const handleSubmit = () => {
        if(selectedOption){
            setShowModal(false);
            dispatch(createAudioStudio({language_id:selectedOption?.value}))
        }
    }
    return (
        <>
            <button onClick={() => setShowModal(true)} className='flex items-center justify-center gap-1.5 bg-colorPrimary text-white px-4 py-2 rounded-lg text-sm ml-auto w-full md:w-auto mt-2 md:mt-0 '>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_413_106)"><path d="M7.00011 13.3635C5.74151 13.3635 4.51116 12.9903 3.46467 12.291C2.41817 11.5918 1.60253 10.5979 1.12088 9.43513C0.639232 8.27233 0.513211 6.99281 0.758753 5.75839C1.0043 4.52396 1.61037 3.39007 2.50034 2.5001C3.39031 1.61013 4.52421 1.00405 5.75863 0.758509C6.99305 0.512967 8.27257 0.638988 9.43537 1.12064C10.5982 1.60228 11.592 2.41793 12.2913 3.46442C12.9905 4.51092 13.3637 5.74126 13.3637 6.99987C13.3637 8.68761 12.6933 10.3062 11.4999 11.4996C10.3065 12.6931 8.68785 13.3635 7.00011 13.3635ZM7.00011 1.90896C5.99323 1.90896 5.00895 2.20754 4.17176 2.76693C3.33456 3.32633 2.68205 4.12142 2.29673 5.05166C1.91141 5.9819 1.81059 7.00552 2.00703 7.99306C2.20346 8.9806 2.68832 9.88771 3.4003 10.5997C4.11227 11.3117 5.01939 11.7965 6.00693 11.993C6.99447 12.1894 8.01808 12.0886 8.94832 11.7033C9.87856 11.3179 10.6737 10.6654 11.233 9.82823C11.7924 8.99103 12.091 8.00676 12.091 6.99987C12.091 5.64968 11.5547 4.35478 10.5999 3.40005C9.6452 2.44532 8.35031 1.90896 7.00011 1.90896Z" fill="white" /><path d="M7.71264 5.09082H6.28718C5.62645 5.09082 5.09082 5.62645 5.09082 6.28718V7.71264C5.09082 8.37337 5.62645 8.909 6.28718 8.909H7.71264C8.37337 8.909 8.909 8.37337 8.909 7.71264V6.28718C8.909 5.62645 8.37337 5.09082 7.71264 5.09082Z" fill="white" /></g><defs><clipPath id="clip0_413_106"><rect width="14" height="14" fill="white" /></clipPath></defs></svg>
                <span>{languages?.length >0?"New Recording Studio":"Create Recording Studio"}</span>
            </button>
            {showModal ? (
                <>
                    <div
                        className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative md:w-1/2 w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                    <button
                                        className="border-2 px-[5px] border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent text-yellowPrimary float-right text-2xl leading-none font-semibol outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        ×
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 pb-5 pt-0 flex-auto">
                                    <label htmlFor="language" className='text- font-semibold'>Select Language</label>
                                    {/* <div class="flex items-center w-full mt-2">
                                        <select id="language" name="language" class="w-full h-full rounded-md border  bg-transparent py-2.5 pr-2 pl-3 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-colorPrimary sm:text-sm outline-none">
                                            <option>English</option>
                                            <option>Urdu</option>
                                            <option>Arabic</option>
                                            <option>German</option>
                                        </select>
                                    </div> */}
                                     <div className="flex items-center w-[100%] mt-2 p-0 [&>div]:w-full">
                                        <ReactSelect
                                            options={options}
                                            value={selectedOption}
                                            onChange={handleChange}
                                            isSearchable={true}
                                            placeholder="Select Language"
                                            
                                         />
                                     </div>
                                    <div className="mb-2 pt-3 flex gap-3 justify-center mt-4">
                                        <button
                                            className="bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={handleSubmit}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }
        </>
    )
}

export default CreateRecordingModal