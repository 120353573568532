import React, { useEffect, useState } from "react";
// import logo from "../../assets/svgs/logo.svg";
// import logo from "../../assets/images/dipspik.png";
import logo from '../../assets/svgs/dipspik.svg'

import SettingsSvg from "../ui/SettingsSvg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAudioStudio, getRecordings } from "../../redux/audio/action";

const SideBar = () => {
  const dispatch = useDispatch();
  let audiolocation = useLocation()
  audiolocation = audiolocation.pathname.split("/")[1] === "audio"
  console.log("location location audiolocation: ", audiolocation);
  const language = useSelector((state) => state.audioReducer?.getAudioStudio?.data)
  const user = useSelector((state) => state.userReducer?.getUser?.data)
  useEffect(() => {

    if (!language) {
      dispatch(getAudioStudio())
    }
    console.log("language in sidebar", language)
  }, [language, user])
  const [isAudioOpen, setAudioOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const handleAudioTab = () => {
    setAudioOpen(!isAudioOpen);
  }
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  return (
    <>
      <button
        data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-800 rounded-lg sm:hidden hover:text-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-gray-200"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-50"
          onClick={closeSidebar}
        ></div>
      )}

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="bg-gray-50 h-full px-5 py-4 overflow-y-auto flex flex-col">
          <NavLink onClick={closeSidebar} to="/admin/" className="flex items-center justify-center pt-5">
            <img src={logo} className="w-36  m-auto" alt="Flowbite Logo" />
          </NavLink>
          <hr className="h-px my-8 bg-secondary border-0" />
          <ul className="space-y-2 font-medium mb-12">
            <li>
              <NavLink onClick={closeSidebar} to="/home" className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                <svg className={`flex-shrink-0 w-5 h-5 text-gray-800 transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 22V12H15V22M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span className="ms-3 leading-none font-semibold">Home</span>
              </NavLink>
            </li>
            {
              (language && language?.length > 0) ?
                <li>
                  <button onClick={handleAudioTab} to="/audio" className={`flex items-center p-2 text-gray-800 hover:text-colorPrimary group font-semibold text-lg ${audiolocation && " text-colorPrimary"}`}>
                    <svg className={`flex-shrink-0 w-5 h-5  transition duration-75 group-hover:text-colorPrimary ${audiolocation ? " text-colorPrimary" : "text-gray-800"}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4V20C11 20.2652 11.1054 20.5196 11.2929 20.7071C11.4804 20.8946 11.7348 21 12 21C12.2652 21 12.5196 20.8946 12.7071 20.7071C12.8946 20.5196 13 20.2652 13 20V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3ZM8 5C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6V18C7 18.2652 7.10536 18.5196 7.29289 18.7071C7.48043 18.8946 7.73478 19 8 19C8.26522 19 8.51957 18.8946 8.70711 18.7071C8.89464 18.5196 9 18.2652 9 18V6C9 5.73478 8.89464 5.48043 8.70711 5.29289C8.51957 5.10536 8.26522 5 8 5ZM4 7C3.73478 7 3.48043 7.10536 3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V16C3 16.2652 3.10536 16.5196 3.29289 16.7071C3.48043 16.8946 3.73478 17 4 17C4.26522 17 4.51957 16.8946 4.70711 16.7071C4.89464 16.5196 5 16.2652 5 16V8C5 7.73478 4.89464 7.48043 4.70711 7.29289C4.51957 7.10536 4.26522 7 4 7ZM16 5C15.7348 5 15.4804 5.10536 15.2929 5.29289C15.1054 5.48043 15 5.73478 15 6V18C15 18.2652 15.1054 18.5196 15.2929 18.7071C15.4804 18.8946 15.7348 19 16 19C16.2652 19 16.5196 18.8946 16.7071 18.7071C16.8946 18.5196 17 18.2652 17 18V6C17 5.73478 16.8946 5.48043 16.7071 5.29289C16.5196 5.10536 16.2652 5 16 5ZM20 7C19.7348 7 19.4804 7.10536 19.2929 7.29289C19.1054 7.48043 19 7.73478 19 8V16C19 16.2652 19.1054 16.5196 19.2929 16.7071C19.4804 16.8946 19.7348 17 20 17C20.2652 17 20.5196 16.8946 20.7071 16.7071C20.8946 16.5196 21 16.2652 21 16V8C21 7.73478 20.8946 7.48043 20.7071 7.29289C20.5196 7.10536 20.2652 7 20 7Z" fill="currentColor" />
                    </svg>
                    <span className={`flex-1 ms-3 whitespace-nowrap font-semibold ${audiolocation && " text-colorPrimary"}`}>
                      Audio Assets
                    </span>
                    <svg class={`w-5 h-5 ml-2 ${isAudioOpen && "rotate-180"} ${audiolocation ? "text-colorPrimary" : "text-gray-800"} transition duration-75 group-hover:text-colorPrimary`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                    </svg>
                  </button>
                  {
                    isAudioOpen &&
                    <ul className=" p-2 mt-2 w-full">
                      {
                        language.map((item) =>
                          <li className=" bg-gray-200 w-full rounded-md mb-2">
                            <NavLink onClick={() => { closeSidebar(); dispatch(getRecordings(item?.language_id)) }} to={`/audio/${item?.language_id}/${item?.name}`} className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold`} style={{ textWrap: "nowrap" }}>
                              <span className="my-1 leading-none font-medium">{item?.name}</span>
                            </NavLink>
                          </li>)
                      }
                    </ul>
                  }

                </li> :
                <li>
                  <NavLink onClick={closeSidebar} to="/audio" className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                    <svg className={`flex-shrink-0 w-5 h-5 text-gray-800 transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4V20C11 20.2652 11.1054 20.5196 11.2929 20.7071C11.4804 20.8946 11.7348 21 12 21C12.2652 21 12.5196 20.8946 12.7071 20.7071C12.8946 20.5196 13 20.2652 13 20V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3ZM8 5C7.73478 5 7.48043 5.10536 7.29289 5.29289C7.10536 5.48043 7 5.73478 7 6V18C7 18.2652 7.10536 18.5196 7.29289 18.7071C7.48043 18.8946 7.73478 19 8 19C8.26522 19 8.51957 18.8946 8.70711 18.7071C8.89464 18.5196 9 18.2652 9 18V6C9 5.73478 8.89464 5.48043 8.70711 5.29289C8.51957 5.10536 8.26522 5 8 5ZM4 7C3.73478 7 3.48043 7.10536 3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V16C3 16.2652 3.10536 16.5196 3.29289 16.7071C3.48043 16.8946 3.73478 17 4 17C4.26522 17 4.51957 16.8946 4.70711 16.7071C4.89464 16.5196 5 16.2652 5 16V8C5 7.73478 4.89464 7.48043 4.70711 7.29289C4.51957 7.10536 4.26522 7 4 7ZM16 5C15.7348 5 15.4804 5.10536 15.2929 5.29289C15.1054 5.48043 15 5.73478 15 6V18C15 18.2652 15.1054 18.5196 15.2929 18.7071C15.4804 18.8946 15.7348 19 16 19C16.2652 19 16.5196 18.8946 16.7071 18.7071C16.8946 18.5196 17 18.2652 17 18V6C17 5.73478 16.8946 5.48043 16.7071 5.29289C16.5196 5.10536 16.2652 5 16 5ZM20 7C19.7348 7 19.4804 7.10536 19.2929 7.29289C19.1054 7.48043 19 7.73478 19 8V16C19 16.2652 19.1054 16.5196 19.2929 16.7071C19.4804 16.8946 19.7348 17 20 17C20.2652 17 20.5196 16.8946 20.7071 16.7071C20.8946 16.5196 21 16.2652 21 16V8C21 7.73478 20.8946 7.48043 20.7071 7.29289C20.5196 7.10536 20.2652 7 20 7Z" fill="currentColor" />
                    </svg>
                    <span className="flex-1 ms-3 whitespace-nowrap font-semibold">
                      Audio Assets
                    </span>
                  </NavLink>
                </li>
            }

            {/* <li>
              <NavLink onClick={closeSidebar} to='/expression' className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                <svg className={`flex-shrink-0 w-5 h-5 text-gray-800 transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9624 1.5C17.7577 1.5 22.4624 6.20475 22.4624 12C22.4624 17.7952 17.7577 22.5 11.9624 22.5C6.16715 22.5 1.4624 17.7952 1.4624 12C1.4624 6.20475 6.16715 1.5 11.9624 1.5ZM11.9624 3C6.99515 3 2.9624 7.03275 2.9624 12C2.9624 16.9672 6.99515 21 11.9624 21C16.9297 21 20.9624 16.9672 20.9624 12C20.9624 7.03275 16.9297 3 11.9624 3ZM16.6364 12.8467C16.7744 12.4567 17.2034 12.252 17.5934 12.39C17.9842 12.5288 18.1889 12.957 18.0502 13.3477C17.1689 15.8385 14.7914 17.625 11.9999 17.625C9.2084 17.625 6.8309 15.8385 5.94965 13.3477C5.8109 12.957 6.01565 12.5288 6.4064 12.39C6.7964 12.252 7.2254 12.4567 7.3634 12.8467C8.03915 14.7562 9.8609 16.125 11.9999 16.125C14.1389 16.125 15.9607 14.7562 16.6364 12.8467ZM8.9999 6.375C10.2419 6.375 11.2499 7.383 11.2499 8.625C11.2499 9.867 10.2419 10.875 8.9999 10.875C7.7579 10.875 6.7499 9.867 6.7499 8.625C6.7499 7.383 7.7579 6.375 8.9999 6.375ZM8.9999 7.875C8.5859 7.875 8.2499 8.211 8.2499 8.625C8.2499 9.039 8.5859 9.375 8.9999 9.375C9.4139 9.375 9.7499 9.039 9.7499 8.625C9.7499 8.211 9.4139 7.875 8.9999 7.875ZM14.9999 6.375C16.2419 6.375 17.2499 7.383 17.2499 8.625C17.2499 9.867 16.2419 10.875 14.9999 10.875C13.7579 10.875 12.7499 9.867 12.7499 8.625C12.7499 7.383 13.7579 6.375 14.9999 6.375ZM14.9999 7.875C14.5859 7.875 14.2499 8.211 14.2499 8.625C14.2499 9.039 14.5859 9.375 14.9999 9.375C15.4139 9.375 15.7499 9.039 15.7499 8.625C15.7499 8.211 15.4139 7.875 14.9999 7.875Z" fill="currentColor" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap font-semibold ">
                  Expressions
                </span>
              </NavLink>
            </li> */}
            <li>
              <NavLink onClick={closeSidebar} to='/video' className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                <svg className={`flex-shrink-0 w-5 h-5 text-current transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.4365 21H4.56396C3.5694 21 2.61558 20.6049 1.91231 19.9016C1.20905 19.1984 0.813965 18.2446 0.813965 17.25V6.75C0.813965 5.75544 1.20905 4.80161 1.91231 4.09835C2.61558 3.39509 3.5694 3 4.56396 3H19.4365C20.431 3 21.3849 3.39509 22.0881 4.09835C22.7914 4.80161 23.1865 5.75544 23.1865 6.75V17.25C23.1865 18.2446 22.7914 19.1984 22.0881 19.9016C21.3849 20.6049 20.431 21 19.4365 21ZM4.56396 4.5C3.96723 4.5 3.39493 4.73705 2.97297 5.15901C2.55102 5.58097 2.31396 6.15326 2.31396 6.75V17.25C2.31396 17.8467 2.55102 18.419 2.97297 18.841C3.39493 19.2629 3.96723 19.5 4.56396 19.5H19.4365C20.0332 19.5 20.6055 19.2629 21.0275 18.841C21.4494 18.419 21.6865 17.8467 21.6865 17.25V6.75C21.6865 6.15326 21.4494 5.58097 21.0275 5.15901C20.6055 4.73705 20.0332 4.5 19.4365 4.5H4.56396Z" fill="currentColor" /><path d="M8.95508 16.6762C8.75617 16.6762 8.5654 16.5972 8.42475 16.4565C8.2841 16.3159 8.20508 16.1251 8.20508 15.9262V8.07369C8.20542 7.94438 8.23919 7.81737 8.3031 7.70496C8.36702 7.59256 8.45891 7.49859 8.56985 7.43218C8.68079 7.36576 8.80702 7.32916 8.93628 7.32592C9.06554 7.32268 9.19345 7.35291 9.30758 7.41369L16.6313 11.3399C16.7504 11.4042 16.8499 11.4995 16.9192 11.6157C16.9885 11.7319 17.0251 11.8646 17.0251 11.9999C17.0251 12.1352 16.9885 12.268 16.9192 12.3842C16.8499 12.5004 16.7504 12.5957 16.6313 12.6599L9.30758 16.5862C9.19925 16.6447 9.07818 16.6756 8.95508 16.6762ZM9.70508 9.32619V14.6737L14.6888 11.9999L9.70508 9.32619Z" fill="currentColor" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap font-semibold">
                  Videos
                </span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink onClick={closeSidebar} to='/avatar' className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                <svg className={`flex-shrink-0 w-5 h-5 text-current transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9996 1.50098C6.20971 1.50098 1.5 6.21068 1.5 12.0006C1.5 17.7904 6.20971 22.5001 11.9996 22.5001C17.7895 22.5001 22.4992 17.7904 22.4992 12.0006C22.4992 6.21068 17.7895 1.50098 11.9996 1.50098ZM11.9996 3.00085C16.9788 3.00085 20.9992 7.02132 20.9992 12.0006C20.9992 14.2867 20.1451 16.3649 18.7464 17.9505C18.7192 15.9091 17.0471 14.2504 14.9995 14.2504H8.9997C6.95208 14.2504 5.27993 15.9091 5.25278 17.9505C3.85406 16.3649 2.99994 14.2867 2.99994 12.0006C2.99994 7.02132 7.02034 3.00085 11.9996 3.00085ZM11.9996 5.2508C9.93748 5.2508 8.24973 6.93853 8.24973 9.00062C8.24973 11.0627 9.93748 12.7505 11.9996 12.7505C14.0617 12.7505 15.7494 11.0627 15.7494 9.00062C15.7494 6.93853 14.0617 5.2508 11.9996 5.2508ZM11.9996 6.75076C13.2511 6.75076 14.2495 7.74915 14.2495 9.00062C14.2495 10.2521 13.2511 11.2506 11.9996 11.2506C10.7481 11.2506 9.74967 10.2521 9.74967 9.00062C9.74967 7.74915 10.7481 6.75076 11.9996 6.75076ZM8.9997 15.7504H14.9995C16.2589 15.7504 17.2494 16.7409 17.2494 18.0003V19.3054C15.7719 20.3678 13.9629 21.0002 11.9996 21.0002C10.0363 21.0002 8.22726 20.3678 6.74979 19.3054V18.0003C6.74979 16.7409 7.74028 15.7504 8.9997 15.7504Z" fill="currentColor" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap font-semibold ">
                  Avatars
                </span>
              </NavLink>
            </li> */}
            <li>
              <NavLink onClick={closeSidebar} to='/settings' className={`flex items-center p-2  text-gray-800 hover:text-colorPrimary group font-semibold text-lg`}>
                <SettingsSvg />
                {/* <svg className={`flex-shrink-0 w-5 h-5 text-current transition duration-75 group-hover:text-colorPrimary`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9996 1.50098C6.20971 1.50098 1.5 6.21068 1.5 12.0006C1.5 17.7904 6.20971 22.5001 11.9996 22.5001C17.7895 22.5001 22.4992 17.7904 22.4992 12.0006C22.4992 6.21068 17.7895 1.50098 11.9996 1.50098ZM11.9996 3.00085C16.9788 3.00085 20.9992 7.02132 20.9992 12.0006C20.9992 14.2867 20.1451 16.3649 18.7464 17.9505C18.7192 15.9091 17.0471 14.2504 14.9995 14.2504H8.9997C6.95208 14.2504 5.27993 15.9091 5.25278 17.9505C3.85406 16.3649 2.99994 14.2867 2.99994 12.0006C2.99994 7.02132 7.02034 3.00085 11.9996 3.00085ZM11.9996 5.2508C9.93748 5.2508 8.24973 6.93853 8.24973 9.00062C8.24973 11.0627 9.93748 12.7505 11.9996 12.7505C14.0617 12.7505 15.7494 11.0627 15.7494 9.00062C15.7494 6.93853 14.0617 5.2508 11.9996 5.2508ZM11.9996 6.75076C13.2511 6.75076 14.2495 7.74915 14.2495 9.00062C14.2495 10.2521 13.2511 11.2506 11.9996 11.2506C10.7481 11.2506 9.74967 10.2521 9.74967 9.00062C9.74967 7.74915 10.7481 6.75076 11.9996 6.75076ZM8.9997 15.7504H14.9995C16.2589 15.7504 17.2494 16.7409 17.2494 18.0003V19.3054C15.7719 20.3678 13.9629 21.0002 11.9996 21.0002C10.0363 21.0002 8.22726 20.3678 6.74979 19.3054V18.0003C6.74979 16.7409 7.74028 15.7504 8.9997 15.7504Z" fill="currentColor" />
                </svg> */}
                <span className="flex-1 ms-3 whitespace-nowrap font-semibold ">
                  Settings
                </span>
              </NavLink>
            </li>
          </ul>
          {/* <div className="relative upgrade bg-colorPrimary p-4 rounded-2xl mt-auto mb-3">
            <div className="z-10 relative">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12.96C0 8.32853 0 6.01279 0.929875 4.25656C1.68028 2.8393 2.8393 1.68028 4.25656 0.929875C6.01279 0 8.32853 0 12.96 0H19.04C23.6715 0 25.9872 0 27.7434 0.929875C29.1607 1.68028 30.3197 2.8393 31.0701 4.25656C32 6.01279 32 8.32853 32 12.96V19.04C32 23.6715 32 25.9872 31.0701 27.7434C30.3197 29.1607 29.1607 30.3197 27.7434 31.0701C25.9872 32 23.6715 32 19.04 32H12.96C8.32853 32 6.01279 32 4.25656 31.0701C2.8393 30.3197 1.68028 29.1607 0.929875 27.7434C0 25.9872 0 23.6715 0 19.04V12.96Z" fill="white" /><path d="M14.9431 9.42208L15.277 9H11.627L8 13.5437H11.6804L14.9431 9.42208ZM12.5539 13.5428H19.4481L17.574 11.1747L16.0017 9.18817L13.6256 12.1894L12.5539 13.5428ZM8.04409 14.2624L14.8987 22.0971L11.6207 14.2624H8.04409ZM19.6554 14.2624H19.0581H16.5469H12.3469L15.9801 22.945L16.0034 23L19.4649 14.7191L19.6554 14.2624ZM17.1127 22.0866L23.9551 14.263H20.383L17.1127 22.0866ZM24 13.5431L20.3724 9.00154H16.7256L20.3213 13.5437L24 13.5431Z" fill="#0067AD" /></svg>
              <div className="flex flex-col my-3">
                <p className="font-semibold text-base text-white">Upgrade to Premium</p>
                <p className="text-white text-sm">Unlock premium features and enhance your experience today!</p>
              </div>
              <button className="bg-white text-colorPrimary rounded-lg py-1.5 w-full font-semibold text-sm">Upgrade Now</button>
            </div>
            <svg className="absolute bottom-0 right-0" width="152" height="168" viewBox="0 0 152 168" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M295.758 166.016C295.758 257.466 229.679 331.5 148.283 331.5C66.8866 331.5 0.807129 257.466 0.807129 166.016C0.807129 74.5663 66.8866 0.532227 148.283 0.532227C229.679 0.532227 295.758 74.5663 295.758 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M275.752 166.016C275.752 245.072 218.629 309.059 148.282 309.059C77.9354 309.059 20.813 245.072 20.813 166.016C20.813 86.9597 77.9354 22.9727 148.282 22.9727C218.629 22.9727 275.752 86.9597 275.752 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M252.987 166.016C252.987 230.969 206.057 283.524 148.283 283.524C90.5086 283.524 43.5786 230.969 43.5786 166.016C43.5786 101.063 90.5086 48.5093 148.283 48.5093C206.057 48.5093 252.987 101.063 252.987 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M230.221 166.016C230.221 216.866 193.483 257.987 148.282 257.987C103.081 257.987 66.3438 216.866 66.3438 166.016C66.3438 115.166 103.081 74.0449 148.282 74.0449C193.483 74.0449 230.221 115.166 230.221 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M208.145 166.016C208.145 203.19 181.291 233.225 148.282 233.225C115.273 233.225 88.4194 203.19 88.4194 166.016C88.4194 128.842 115.273 98.8071 148.282 98.8071C181.291 98.8071 208.145 128.842 208.145 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M188.139 166.016C188.139 190.796 170.242 210.784 148.282 210.784C126.322 210.784 108.425 190.796 108.425 166.016C108.425 141.235 126.322 121.248 148.282 121.248C170.242 121.248 188.139 141.235 188.139 166.016Z" stroke="white" stroke-opacity="0.3" /><path d="M172.133 166.016C172.133 180.881 161.403 192.83 148.283 192.83C135.163 192.83 124.433 180.881 124.433 166.016C124.433 151.152 135.163 139.203 148.283 139.203C161.403 139.203 172.133 151.152 172.133 166.016Z" stroke="white" stroke-opacity="0.3" /></svg>
          </div> */}
        </div>
      </aside >
    </>
  );
};

export default SideBar;
