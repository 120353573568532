import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SelectLanguage from '../../components/ui/SelectLanguage';
import { getLanguage } from '../../redux/language/action';
import ReactSelect from 'react-select';
import { createAudioStudio } from '../../redux/audio/action';

const CloneVoiceModal = ({handleExportCSV,getMetaData}) => {
    const [options, setOptions] = useState([]);
    const dispatch=useDispatch()
    const language=useSelector((state)=>state?.languageReducer?.getLanguage?.data)
    const studio=useSelector((state)=>state?.audioReducer?.createAudioStudio?.message)
    // useEffect(() => {
    //     console.log(studio);
    //     if(studio){
    //         navigate("recording")
    //     }
    // }, [studio])
    const [success, setSuccess] = useState("65")
    
    useEffect(() => {
        if(!language||language===""){
            dispatch(getLanguage())
        }
        else{
            console.log(language)
            const newOptions = language?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setOptions(newOptions);
        }
    }, [language])
    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Selected ID: ${selectedOption?.value}`);
      };
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    }
    const handleSubmit = () => {
        if(selectedOption){
            dispatch(createAudioStudio({language_id:selectedOption?.value}))
        }
    }
    return (
        <>
            <button onClick={() =>{ setShowModal(true);getMetaData()}} className='flex items-center justify-center gap-1.5 bg-colorPrimary text-white px-4 py-2 rounded-lg text-sm  w-full md:w-auto mt-2 md:mt-0 '>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_742_2999)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9266 0.777993C10.8813 0.578828 10.7042 0.4375 10.5 0.4375C10.2958 0.4375 10.1187 0.578828 10.0734 0.777993L9.83132 1.84252C9.71937 2.33489 9.33489 2.71937 8.84251 2.83133L7.77798 3.07339C7.57883 3.11867 7.4375 3.29575 7.4375 3.5C7.4375 3.70425 7.57883 3.88132 7.77798 3.92661L8.84251 4.16868C9.33489 4.28063 9.71937 4.66511 9.83132 5.15749L10.0734 6.22202C10.1187 6.42117 10.2958 6.5625 10.5 6.5625C10.7042 6.5625 10.8813 6.42117 10.9266 6.22202L11.1687 5.15749C11.2806 4.66511 11.6651 4.28063 12.1575 4.16868L13.222 3.92661C13.4212 3.88132 13.5625 3.70425 13.5625 3.5C13.5625 3.29575 13.4212 3.11867 13.222 3.07339L12.1575 2.83133C11.6651 2.71937 11.2806 2.33489 11.1687 1.84252L10.9266 0.777993ZM9.5459 3.5C9.95623 3.29059 10.2906 2.95623 10.5 2.5459C10.7094 2.95623 11.0438 3.29059 11.4541 3.5C11.0438 3.70941 10.7094 4.04377 10.5 4.4541C10.2906 4.04377 9.95623 3.70941 9.5459 3.5Z" fill="white"/>
<path d="M4.8125 3.5C5.05413 3.5 5.25 3.69587 5.25 3.9375V5.90625C5.25 6.14788 5.05413 6.34375 4.8125 6.34375C4.57087 6.34375 4.375 6.14788 4.375 5.90625V3.9375C4.375 3.69587 4.57087 3.5 4.8125 3.5ZM4.375 13.125V8.09375C4.375 7.85212 4.57087 7.65625 4.8125 7.65625C5.05413 7.65625 5.25 7.85212 5.25 8.09375V13.125C5.25 13.3666 5.05413 13.5625 4.8125 13.5625C4.57087 13.5625 4.375 13.3666 4.375 13.125ZM0.875 4.59375C1.11662 4.59375 1.3125 4.78962 1.3125 5.03125V8.3125C1.3125 8.55413 1.11662 8.75 0.875 8.75C0.633375 8.75 0.4375 8.55413 0.4375 8.3125V5.03125C0.4375 4.78962 0.633375 4.59375 0.875 4.59375ZM0.875 10.0625C0.633375 10.0625 0.4375 10.2584 0.4375 10.5V12.0312C0.4375 12.2729 0.633375 12.4688 0.875 12.4688C1.11662 12.4688 1.3125 12.2729 1.3125 12.0312V10.5C1.3125 10.2584 1.11662 10.0625 0.875 10.0625ZM3.28125 5.90625C3.28125 5.66462 3.08538 5.46875 2.84375 5.46875C2.60212 5.46875 2.40625 5.66462 2.40625 5.90625V11.1562C2.40625 11.3979 2.60212 11.5938 2.84375 11.5938C3.08538 11.5938 3.28125 11.3979 3.28125 11.1562V5.90625ZM6.78125 4.8125C7.02288 4.8125 7.21875 5.00837 7.21875 5.25V11.8125C7.21875 12.0541 7.02288 12.25 6.78125 12.25C6.53962 12.25 6.34375 12.0541 6.34375 11.8125V5.25C6.34375 5.00837 6.53962 4.8125 6.78125 4.8125ZM8.75 6.34375C8.99163 6.34375 9.1875 6.53962 9.1875 6.78125V10.2812C9.1875 10.5229 8.99163 10.7188 8.75 10.7188C8.50837 10.7188 8.3125 10.5229 8.3125 10.2812V6.78125C8.3125 6.53962 8.50837 6.34375 8.75 6.34375Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_742_2999">
<rect width="14" height="14" fill="white"/>
</clipPath>
</defs>
</svg>

                
                {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_413_106)"><path d="M7.00011 13.3635C5.74151 13.3635 4.51116 12.9903 3.46467 12.291C2.41817 11.5918 1.60253 10.5979 1.12088 9.43513C0.639232 8.27233 0.513211 6.99281 0.758753 5.75839C1.0043 4.52396 1.61037 3.39007 2.50034 2.5001C3.39031 1.61013 4.52421 1.00405 5.75863 0.758509C6.99305 0.512967 8.27257 0.638988 9.43537 1.12064C10.5982 1.60228 11.592 2.41793 12.2913 3.46442C12.9905 4.51092 13.3637 5.74126 13.3637 6.99987C13.3637 8.68761 12.6933 10.3062 11.4999 11.4996C10.3065 12.6931 8.68785 13.3635 7.00011 13.3635ZM7.00011 1.90896C5.99323 1.90896 5.00895 2.20754 4.17176 2.76693C3.33456 3.32633 2.68205 4.12142 2.29673 5.05166C1.91141 5.9819 1.81059 7.00552 2.00703 7.99306C2.20346 8.9806 2.68832 9.88771 3.4003 10.5997C4.11227 11.3117 5.01939 11.7965 6.00693 11.993C6.99447 12.1894 8.01808 12.0886 8.94832 11.7033C9.87856 11.3179 10.6737 10.6654 11.233 9.82823C11.7924 8.99103 12.091 8.00676 12.091 6.99987C12.091 5.64968 11.5547 4.35478 10.5999 3.40005C9.6452 2.44532 8.35031 1.90896 7.00011 1.90896Z" fill="white" /><path d="M7.71264 5.09082H6.28718C5.62645 5.09082 5.09082 5.62645 5.09082 6.28718V7.71264C5.09082 8.37337 5.62645 8.909 6.28718 8.909H7.71264C8.37337 8.909 8.909 8.37337 8.909 7.71264V6.28718C8.909 5.62645 8.37337 5.09082 7.71264 5.09082Z" fill="white" /></g><defs><clipPath id="clip0_413_106"><rect width="14" height="14" fill="white" /></clipPath></defs></svg> */}
                <span>Generate Voice Clone</span>
            </button>
            {showModal ? (
                <>
                    <div
                        className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative md:w-1/2 w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                    <button
                                        className="border-2 px-[5px] border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent text-yellowPrimary float-right text-2xl leading-none font-semibol outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        ×
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 pb-5 pt-0 flex-auto">
                                    <div className='text-center'>
                                        {
                                            success>="100"?
                                            <>
                                            <h1 className='font-bold mb-3 text-lg'>
                                                Your Voice has been Cloned!
                                            </h1>
                                            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae mollitia qui nesciunt dolor et ea autem odio debitis iure architecto at, animi iusto aperiam perspiciatis. Id laudantium repellendus provident consequuntur.</h1>

                                            </>
                                            :<>
                                            <h1 className='font-bold text-2xl mb-5'>{success}%</h1>
                                        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                    <div class="bg-colorPrimary h-2.5 rounded-full" style={{width: `${success}%`}}></div>
                                        </div>
                                        <h1 className='text-lg mt-5'>Clone your voice...</h1></>
                                        }
                                        
                                    </div>
                                    
                                     
                                    <div className="mb-2 pt-3 flex gap-3 justify-center mt-4">
                                        {
                                            success>"65"?
                                            <button
                                            className="bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>:
                                         <button
                                         className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                         type="button"
                                         onClick={handleSubmit}
                                     >
                                         Next
                                     </button>
                                        }
                                        
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }
        </>
    )
}

export default CloneVoiceModal