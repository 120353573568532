import React, { useEffect, useRef } from 'react';
import ttsIcon from '../../assets/svgs/ttsIcon.svg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreviousNextSlideBtn from '../../components/ui/PreviousNextSlideBtn';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getCloneLanguage } from '../../redux/language/action';

const TtsSelectLanguage = () => {
    const clonelanguages = useSelector((state) => state.languageReducer?.getCloneLanguage?.data);
    const dispatch = useDispatch();
    const sliderRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!clonelanguages) {
            dispatch(getCloneLanguage());
        }
    }, [clonelanguages, dispatch]);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className={`${clonelanguages?.length > 0 ? "block" : "hidden"}`}>
            <div className="flex md:flex-row flex-col gap-5 justify-between bg-gray-100 rounded-lg p-5">
                <div>
                    <h2 className='text-lg font-semibold'>Text to Speech</h2>
                    <p className='text-sm mt-0.5'>Select Language to generate Speech</p>
                </div>
                <img className='xl:w-auto lg:w-[300px] w-[200px] md:mr-0 mx-auto' src={ttsIcon} alt="TTS Icon" />
            </div>

            {clonelanguages?.length > 0 && (
                <>
                    {clonelanguages.length < 4 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 xl:grid-cols-4 2xl:grid-cols-5 mt-5">
                            {clonelanguages.map((l) => (
                                <div key={l.language_id} onClick={() => navigate(`tts/${l?.language_id}/${l?.name}`)}
                                     className="mb-2 rounded-full bg-[#e6f0f7] px-4 py-3 cursor-pointer w-full hover:bg-[#c0e5ff]">
                                    {l.name}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <Slider {...settings} ref={sliderRef}>
                                {clonelanguages.map((l) => (
                                    <div key={l.language_id} className="flex gap-2 pr-2">
                                        <div onClick={() => navigate(`tts/${l?.language_id}/${l?.name}`)}
                                             className="rounded-full bg-[#e6f0f7] px-4 py-3 cursor-pointer hover:bg-[#c0e5ff]">
                                            {l.name}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="mt-3 flex gap-2.5 justify-center">
                                <PreviousNextSlideBtn handleSlide={previous} next={false} />
                                <PreviousNextSlideBtn handleSlide={next} next={true} />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default TtsSelectLanguage;
