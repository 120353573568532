import React from 'react'
import logo from '../../../assets/svgs/dipspik.svg'
import AdminTable from './AdminTable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
export default function Admin() {
    const navigate=useNavigate()
    const data = useSelector((state) => state?.authReducer?.allUser?.data);
  return (
    <div className="flex flex-col lg:justify-betwee justify-cente w-full h-screen">
      <div className='flex justify-between items-center'>
        <img  src={logo} alt="logo" className="ml-6 my-5 h-fit " />
        <button className='mr-6' onClick={()=>navigate('/')}>Go to Login Page</button>
      </div>
      <div className='m-20'>
        <h1 className='text-center font-semibold'>{data?.length} are Regitered Users </h1>
        <div className=' border m-5 rounded-xl'>
        <AdminTable/>
        </div>
        
      </div>
    </div>
  )
}
