import React from 'react';

const SettingSideBar = ({ setSettingsType, settingsType }) => {
    const settingButtons = [
        {
            id: 1,
            btnText: "General Settings"
        },
        // {
        //     id: 2,
        //     btnText: "Payment Settings"
        // },
        // {
        //     id: 3,
        //     btnText: "API Settings"
        // },
        // {
        //     id: 4,
        //     btnText: "Help"
        // },
    ]
    return (
        <div className='profileSideBar container px-3 py-3'>
            <div className='sideBarBtns container max-w-50 mx-auto lg:py-6 flex lg:flex-col flex-row gap-2 overflow-auto'>
                {
                    settingButtons.map(({ id, btnText }) => (
                        <button onClick={() => setSettingsType(id)} className={`w-50 font-medium group rounded-lg hover:border-primaryrounded-lg flex items-center w-full min-w-max px-3 py-2 ${settingsType === id ? "text-colorPrimary bg-[#D4E4F0]" : "text-gray-600"}`} key={id}>
                            <span className='font-semibold'>{btnText}</span>
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default SettingSideBar