import React from 'react'

const DeleteIconSvg = () => {
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.1287 4.63567H20.2298V3.2044C20.2298 2.23858 19.4444 1.45312 18.4786 1.45312H13.5215C12.5557 1.45312 11.7702 2.23858 11.7702 3.2044V4.63567H6.87129C5.52147 4.63567 4.41602 5.73531 4.41602 7.09094V9.49967C4.41602 9.81967 4.67783 10.0815 4.99783 10.0815H6.21383V28.0946C6.21383 29.4502 7.31347 30.5499 8.66329 30.5499H23.3309C24.6866 30.5499 25.7862 29.4502 25.7862 28.0946V10.0757H27.0022C27.3222 10.0757 27.584 9.81385 27.584 9.49385V7.08512C27.584 5.73531 26.4844 4.63567 25.1287 4.63567ZM12.9338 3.2044C12.9338 2.87858 13.1957 2.61676 13.5215 2.61676H18.4786C18.8044 2.61676 19.0662 2.87858 19.0662 3.2044V4.63567H12.9338V3.2044ZM24.6226 28.0888C24.6226 28.8044 24.0466 29.3804 23.3309 29.3804H8.66329C7.95347 29.3804 7.37747 28.8044 7.37747 28.0888V10.0757H24.6226V28.0888ZM26.4204 8.91203H5.57965V7.08512C5.57965 6.37531 6.15565 5.79349 6.87129 5.79349H25.1346C25.8444 5.79349 26.4262 6.36949 26.4262 7.08512V8.91203H26.4204Z" fill="currentColor" />
                <path d="M17.9029 27.0564C18.2229 27.0564 18.4847 26.7946 18.4847 26.4746V12.9881C18.4847 12.6681 18.2229 12.4062 17.9029 12.4062C17.5829 12.4062 17.3211 12.6681 17.3211 12.9881V26.4746C17.3211 26.7946 17.5829 27.0564 17.9029 27.0564ZM14.0978 27.0564C14.4178 27.0564 14.6796 26.7946 14.6796 26.4746V12.9881C14.6796 12.6681 14.4178 12.4062 14.0978 12.4062C13.7778 12.4062 13.516 12.6681 13.516 12.9881V26.4746C13.516 26.7946 13.772 27.0564 14.0978 27.0564ZM21.7138 27.0564C22.0338 27.0564 22.2956 26.7946 22.2956 26.4746V12.9881C22.2956 12.6681 22.0338 12.4062 21.7138 12.4062C21.3938 12.4062 21.132 12.6681 21.132 12.9881V26.4746C21.132 26.7946 21.3938 27.0564 21.7138 27.0564ZM10.2869 27.0564C10.6069 27.0564 10.8687 26.7946 10.8687 26.4746V12.9881C10.8687 12.6681 10.6069 12.4062 10.2869 12.4062C9.9669 12.4062 9.70508 12.6681 9.70508 12.9881V26.4746C9.70508 26.7946 9.9669 27.0564 10.2869 27.0564Z" fill="currentColor" />
            </svg>

        </>
    )
}

export default DeleteIconSvg