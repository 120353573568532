import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from '../../redux/language/action';
import ReactSelect from 'react-select';
import { getChapter } from '../../redux/audio/action';
import { getVideoPrompt } from '../../redux/video/action';

const SelectLanguage = ({setLanguage}) => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state?.languageReducer?.getLanguage?.data)
    // const chapters = useSelector((state) => state?.audioReducer?.getChapter?.data)
    // const videoPrompt = useSelector((state) => state?.videoReducer?.getVideoPrompt?.data)
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        if (!language || language === "") {
            dispatch(getLanguage())
        }
        else {
            console.log(language)
            const newOptions = language?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setOptions(newOptions);
        }
    }, [language])

    // useEffect(() => {
    //     if (chapters) {
    //         console.log("chapter id to get prompts...........", chapters[0]?.id)
    //         dispatch(getVideoPrompt(chapters?.emotion[0]?.id))
    //     }

    // }, [chapters])

    // useEffect(() => {
    //     if (videoPrompt) {
    //         console.log(videoPrompt);
    //     }
    // }, [videoPrompt])




    const handleChange = (selectedOption) => {
        // dispatch(getChapter(selectedOption?.value))
        setSelectedOption(selectedOption);
        setLanguage(selectedOption?.value);
        console.log(`Selected ID: ${selectedOption?.value}`);
    };
    return (
        <>
            <label htmlFor="language" className='text- font-semibold'>Select Language</label>
            <div class="flex items-center w-full mt-2">
                <div className="flex items-center w-[100%] mt-2 p-0 [&>div]:w-full">
                    <ReactSelect
                        options={options}
                        value={selectedOption}
                        onChange={handleChange}
                        isSearchable={true}
                        placeholder="Select Language"

                    />
                </div>
            </div>
        </>
    )
}

export default SelectLanguage