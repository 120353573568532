import React, { useEffect } from 'react'
import SideBar from './SideBar'
import NavBar from './NavBar'
import { Navigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/user/action'
import { useAuth0 } from '@auth0/auth0-react'

const Layout = ({ children }) => {
  const isAuth = useSelector((state) => state?.authReducer?.isAuthenticated)
  const dispatch = useDispatch();
  const { user, loginWithPopup, isAuthenticated } = useAuth0();
  console.log("isAUthdmlksandlnsa:", isAuthenticated)
  if (!isAuth && !isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <SideBar />
      <div className="sm:ml-64">
        <NavBar />
        {children}
      </div>
    </>
  )
}

export default Layout