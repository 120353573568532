import React, { useEffect, useRef, useState } from 'react'
import upload_cloud from '../../../assets/svgs/upload_cloud.svg'
import InputField from '../../../components/ui/InputField';
import { NavLink } from 'react-router-dom';
import loader from '../../../assets/svgs/loader.svg'
import ThirdPartySignin from '../../../components/ui/ThirdPartySignin';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../redux/uploadFile/action';
// import { useAuth0 } from '@auth0/auth0-react';
import { signup } from '../../../redux/auth/action';
import PhoneInput from 'react-phone-input-2';
import ImageModal from './ImageModal';
// import IntlTelInput from 'react-intl-tel-input';
export default function RegisterForm({ setScreenStep }) {

    
    const file=useSelector((state)=>state?.uploadFileReducer?.uploadFile?.data)
    const loading=useSelector((state)=>state?.authReducer?.signup?.loading)
    const response=useSelector((state)=>state?.authReducer)
    useEffect(() => {
        if(response?.signup?.status==="verify"||response?.login?.status==="verify"){
            setScreenStep("otp");
        }
    
    }, [response])
    console.log("response:",response);
    useEffect(() => {
        if(response){
            console.log("response:",response);
        }
      
    }, [])

  
    
    const dispatch=useDispatch()
    const fileRef = useRef();
    const [data, setData] = useState({
        name:"",
        email:"",
        // password:"",
        phone:"",
        profile_picture:"",
        type:"CUSTOM",
    })
    useEffect(() => {
        console.log(file);
        if(file){
            console.log(file);
          setData(prevState => ({
              ...prevState,
              profile_picture: file
          }))
        }
        console.log(data)
      }, [file])
    const handleValues= (value,type)=>{
        console.log(value,type);
        if(type==='name'){
            setData(prevState => ({
                ...prevState,
                name: value
            }))
        }
        if(type==='email'){
            setData(prevState => ({
                ...prevState,
                email: value
            }))
           
        }
        if (type === 'phone') {
            setData(prevState => ({
                ...prevState,
                phone: `+${value}`  // Ensure the phone number starts with '+'
            }))
        }
        // if(type==='password'){
        //     setData(prevState => ({
        //         ...prevState,
        //         password: value
        //     }))
        // }
        if(type==='profile_picture'){
            setData(prevState => ({
                ...prevState,
                profile_picture: value
            }))
        }
        console.log(data);
    }
    const handleFile = (e) => {
        const file = e.target.files[0];
        dispatch(uploadFile(file));

      }
    //   setScreenStep("verify")

    const submit=()=>{
        localStorage.setItem('email',data.email)
        localStorage.setItem('otpPhone',data.phone)
        dispatch(signup(data))
        
    }
    return (
        <div className="flex flex-col sm:w-[406px] mx-5 sm:mx-auto grow">
            <div className="flex flex-col gap-3 my-auto">
                <div className="mb-3">
                    <h1 className="text-colorPrimary text-2xl font-semibold text-center">Start Your Registration!</h1>
                </div>
                <div className="flex gap-4 justify-center mb-3">
                    <ImageModal  data={data} setData={setData}/>
                </div>
                <InputField label={"Enter Your Name"} type={"name"} value={data?.name} onChange={handleValues} />
                <InputField label={"Enter Email"} type={"email"} value={data?.email} onChange={handleValues}  />
                <div className='flex flex-col'>
                 <label htmlFor="phone" className='text-sm font-semibold mb-1'>Phone Number</label>
<PhoneInput
  country={"pk"}
  enableSearch={true}
  value={data.phone}
  onChange={(phone) => handleValues(phone, 'phone')}
  inputProps={{
    name: 'phone',
    required: true,
    autoFocus: true,
    containerClass: `block w-full rounded-md border-0 pl-2.5 pr-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-colorPrimary sm:text-sm sm:leading-6 focus:outline-none`
  }}
/>
                 </div>
                {/* <InputField label={"Enter Password"} type={"password"} value={data?.password} onChange={handleValues} /> */}
                
                <div>
                <button type="submit" onClick={submit} className={`flex w-full justify-center rounded-md ${loading?'bg-gray-100 hover:bg-white py-0 ':'bg-colorPrimary py-1.5 '}  px-3   text-sm font-semibold leading-6 text-white shadow-sm hover:bg-colorPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-colorPrimary`}>{loading?<img  className='w-10' src={loader} alt="" />:'Sign up' }</button>
                </div>
                {/* <div className="flex items-center gap-2.5">
                    <hr className='w-full' />
                    <p className='text-gray-500'>or</p>
                    <hr className='w-full' />
                </div>
                <ThirdPartySignin type="Sign up" /> */}
                <p className="mt-3 text-center text-sm text-gray-500">
                    Already have an account?{' '}
                    <NavLink to="/" className="font-semibold leading-6 text-colorPrimary">
                        Log in
                    </NavLink>
                </p>
            </div>
        </div>
    )
}
