import React from 'react'
import thumb from '../../assets/images/thumbnails/thumbnail2.png'
import MyVideos from './MyVideos'

const Videos = () => {
    return (
        <>
            <div className="px-6 py-4 rounded-lg flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h1 className='font-semibold text-xl'>Video Assests</h1>
                </div>
                <div className="flex justify-between items-center flex-wrap bg-gray-100 rounded-xl py-4 px-5 gap-2 mb-1">
                    <div className="flex items-center gap-3 flex-wrap">
                        <img src={thumb} alt="thumbnail" className='rounded-xl w-[121px]' />
                        <div className=''>
                            <p className='font-semibold mb-2'>Make Videos & Clone your twin</p>
                            <p className='text-gray-600 text-sm'>You are not familiar how to make a video? Explore the tutorial guide</p>
                        </div>
                    </div>
                    <div className='ml-auto'>
                        <button className='bg-colorPrimary text-white px-5 py-2 rounded-lg text-sm'>Learn More</button>
                    </div>
                </div>
                <MyVideos />
            </div >
        </>
    )
}

export default Videos