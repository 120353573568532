import React from 'react'
import toturialVideo from '../../../assets/videos/tutorial.mp4'
import { UPLOAD_OPTIONS } from '../../../constants/VideoModalStepper'

const TutorialVideo = ({ setStepType }) => {
    const handleSubmit = () => {
        setStepType(UPLOAD_OPTIONS)
    }
    return (
        <>
            <div className="relative p-6 pb-5 pt-0 flex-auto">
                <div className="px-6 pt-3 pb-4">
                    <video controls className='rounded-lg'>
                        <source src={toturialVideo} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                    <p className='text-sm text-gray-500 mt-4 text-center'>View detailed instructions <b className='cursor-pointer'>here</b> or <b className='cursor-pointer'>download</b> it as PDF</p>
                </div>
                <div className="mb-2 flex gap-3 justify-center">
                    <button
                        className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default TutorialVideo