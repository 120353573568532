import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const PublicRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
  const { user, loginWithPopu, isAuthenticated } = useAuth0();

  console.log("isAUthdmlksandlnsa:", isAuthenticated)
  if (isAuth || isAuthenticated) {
    return <Navigate to="/home" />;
  }

  return (
    <>
      {children}
    </>
  );
};