import React, { useEffect } from 'react';
import DeleteIconSvg from '../../../components/ui/DeleteIconSvg';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGeneratedVoice, getGeneratedVoices } from '../../../redux/audio/action';
const SkeletonLoader = () => (
    <div className="shadow-sm w-full border bg-gray-100 rounded flex items-center min-w-max px-2 py-2.5 whitespace-nowrap font-medium text-xs relative">
        <div className="bg-gray-300 animate-pulse w-5 h-5 rounded absolute top-2 right-2"></div>
        <div className="w-full flex items-center">
       
            <div className="flex flex-col w-full">
             
                <div className="bg-gray-300 animate-pulse rounded-full px-2 py-1 mb-2 w-24 h-4"></div>
              
                <div className="bg-gray-300 animate-pulse h-4 mb-2 w-32"></div>
              
                <div className="bg-gray-300 animate-pulse h-6 w-full rounded-full"></div>
            </div>
        </div>
    </div>
);
const decodeURL = (url) => {
    return decodeURIComponent(url);
};

const SettingSideBar = ({ handleClear }) => {
    const data = useSelector((state) => state.userReducer?.getUser?.data);
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const voices = useSelector((state) => state.audioReducer?.getGeneratedVoices?.data);
    const loading = useSelector((state) => state.audioReducer?.getGeneratedVoices?.loading);

    const encodedName = name?.replace(/\s/g, '');

    const URL = decodeURL(`https://dipspik.com/soulcast/public/uploads/${data?.id}/recordings/${encodedName}/generated-audios/`);

    useEffect(() => {
        if (!voices && id) {
            dispatch(getGeneratedVoices(id));
        }
    }, [voices, id, dispatch]);

    // Identify the index of the last item in the original list
    const lastIndex = voices?.length - 1;

    return (
        <>
            <div className='flex'>
                <button 
                    onClick={handleClear} 
                    className='bg-colorPrimary text-white mx-4 px-3 py-1 rounded-lg text-sm flex gap-2 justify-between items-center grow'
                >
                    <p className='flex gap-1'>Create <span className='xl:block hidden'>New</span> Speech</p>
                    <span className='text-3xl'>+</span>
                </button>
            </div>
            <p className='border-y py-3 px-4 my-5 text-colorPrimary'>Recent Voices</p>
            <div className='profileSideBar container px-4 my-4 lg:h-scree'>
                <div className='sideBarBtns container max-w-50 mx-auto flex lg:flex-col flex-row gap-2 lg:overflow-hidden overflow-auto'>
                    {
                        loading?<>
                        <SkeletonLoader/>
                        <SkeletonLoader/>
                        <SkeletonLoader/>
                        </>
                        :
                        voices?.slice().reverse().map((item, index) => (
                            <div 
                                className={`shadow-sm w-50 border group bg-gray-100 rounded hover:border-colorPrimary hover:bg-[#e6f0f7] flex items-center w-full min-w-max px-2 py-2.5 whitespace- font-medium text-xs relative cursor-pointer `}
                                key={index}
                            >
                                <div onClick={()=>dispatch(deleteGeneratedVoice({language_id:id,id:item?.id}))} className="text-white bg-colorPrimary px-1.5 py-0.5 h-ful rounded [&>svg]:w-5 group-hover:block hidden absolute top-0 -right-[1px] shadow-md">
                                    <DeleteIconSvg />
                                </div>
                                <div className='w-ful w-[185px] grow'>
                                    {
                                        index === 0 &&<span className='bg-colorPrimary text-white py-1 rounded-full px-2'>Latest</span>
                                    }
                                    <p className='group-hover:text-primary truncate w-[185px] grow my-2'>{item?.prompt}</p>
                                    
                                    <audio className='w-full border rounded-full mt-2.5' controls src={`${URL}/${item?.audio}`} preload={index!==0&&"none"}></audio>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default SettingSideBar;
