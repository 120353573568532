import React, { useEffect, useState } from 'react'
import RecordingAudio from './RecordingAudio'
import { useNavigate, useParams } from 'react-router';
import { getChapter, getChapterPrompt, setChapterPrompt, setChapterPromptMessage } from '../../redux/audio/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import loader from '../../assets/svgs/loader.svg'
import { Typewriter } from 'react-simple-typewriter';


const RecordingPage = ({expression}) => {
    const [addedPrompts, setAddedPrompts] = useState()
    const [audioUrl, setAudioUrl] = useState("")
    const {id,filterprm,filterId}=useParams();
    const [data, setData] = useState({
        language_id:id,
        chapter_id:"",
        topic_id:"",
        prompt:"",
        audio:"",
    })
    const [recording, setRecording] = useState()
    
    const dispatch=useDispatch();
    const chapters=useSelector((state)=>state?.audioReducer?.getChapter?.data)
    const prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.prompts)
    const promptLoading=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.loading)
    let remainingPrompt=JSON.parse(localStorage.getItem('prompts'))
    const response=useSelector((state)=>state?.audioReducer?.setChapterPrompt?.message)
    const loading=useSelector((state)=>state?.audioReducer?.setChapterPrompt?.loading)
    // const loading=true
    const total_prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.total_prompts_count)
    const added_prompt=useSelector((state)=>state?.audioReducer?.getChapterPrompt?.data?.total_prompts_add_user)
    const chapterLoading = useSelector((state) => state?.audioReducer?.getChapter?.loading)

    useEffect(() => {
        console.log("response||||||||||||||||||||||||||||||||||",response)
      if(response){
        
        dispatch(setChapterPromptMessage());
        setAudioUrl(null);
        
        let pro= JSON.parse(localStorage.getItem('prompts'))
        pro.shift();
        setAddedPrompts(addedPrompts+1);
        localStorage.setItem('prompts',JSON.stringify(pro))
        console.log("asjdnbjsabdkdlksadn data after shift in response of abi",pro);
        setData({
            ...data,
            prompt:pro[0]?.prompt,
            topic_id:pro[0]?.id,
            chapter_id:pro[0]?.chapter_id

        })
      }
    }, [response])

    const [filter, setFilter] = useState(filterprm);
    useEffect(() => {
        if (!chapters) {
            if (id !== null) {
                dispatch(getChapter(id));
            }
        }
        setFilter(filterprm)
        dispatch(getChapterPrompt(filterId))
        console.log("get chapters", chapters);
    }, [chapters])
    useEffect(() => {
        if(prompt){           
           localStorage.setItem('prompts',JSON.stringify(prompt))
           let pro=JSON.parse(localStorage.getItem('prompts'))
            setData({
                ...data,
                prompt:pro[0]?.prompt,
                topic_id:pro[0]?.id,
                chapter_id:pro[0]?.chapter_id

            })
            console.log("prompt........................:::::::::::::::::::::",prompt)
        }
    }, [prompt])

    useEffect(() => {
       setAddedPrompts(added_prompt);
      }, [added_prompt])
    useEffect(() => {
      if(!chapters){
        if(id!==null){
            dispatch(getChapter(id));
        }
      }
      console.log("get chapters",chapters);
    }, [chapters]) 
    useEffect(() => {
          if(id!==null){
              dispatch(getChapter(id));
          }
          console.log("get chapters",chapters);
      }, [id])
    const navigate = useNavigate();
    const handleSubmit = () => {
        setAudioUrl(null);
        // navigate(-1)
        console.log("data:::::::::::::::::::::::::::::::::::",data)
        // console.log({audio:recording,prompt:prompt[0]?.prompt,id:id})
        dispatch(setChapterPrompt(data))   
    }
const getPrompts=(item)=>{
    setData({...data,chapter_id:item?.id})
    setFilter(item?.name)
    dispatch(getChapterPrompt(item?.id))

   
}
    
    return (
        <div className="px-6 py-6 rounded-lg flex flex-col gap-6">
            <div>
                <h2 className='text-lg font-semibold'>Dipspik Learning Guide</h2>
                <p className='text-gray-600 text-sm font-normal'>You are not familiar how to record audio, explore the tutorial guide</p>
            </div>
            <div>
                <h2 className='text-lg font-semibold'>Select Expression</h2>
                <div className='flex flex-wrap mt-3 gap-x-2 gap-y-2'>
                {
                    chapterLoading?
                //     <div className="w-44 h-24 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-lg border-2">
                //     <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
                //     <div className="w-24 h-4 bg-gray-300 mt-2 rounded-full"></div>
                // </div>


                <div className='flex flex-wrap justify-between gap-x-2'>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                                                    </div>
                
                
                :
                       chapters && chapters.emotion?.map((item) =>
                        // <button onClick={() => getPrompts(item)} className={`border border-colorPrimary p-5  text-colorPrimary flex flex-col items-center justify-center rounded-lg border-2 hover:bg-colorPrimaryHover ${filter === item?.name && "bg-colorPrimaryHover"} gap-y-2`}>
                        //     <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //         <circle cx="12.5" cy="12" r="11.25" stroke="#0067AD" stroke-width="1.5" />
                        //         <circle cx="7.94814" cy="8.27627" r="1.65517" fill="#0067AD" />
                        //         <circle cx="17.0517" cy="8.27627" r="1.65517" fill="#0067AD" />
                        //         <path d="M18.9484 13.6553C19.5007 13.6553 19.9554 14.1054 19.8821 14.6528C19.3946 18.2947 16.2753 21.1035 12.5 21.1035C8.72473 21.1035 5.60545 18.2947 5.11798 14.6528C5.04471 14.1054 5.49957 13.6553 6.05186 13.6553C9.41442 13.6553 12.5 13.6553 12.5 13.6553C12.5 13.6553 15.8612 13.6553 18.9484 13.6553Z" fill="#0067AD" />
                        //     </svg>
                        //     {item?.name}
                        // </button>
                        <button onClick={() => {getPrompts(item)}} className={`border border-gray-300 px-5 py-2  text-gray-500 flex flex-col items-center justify-center rounded-full border-2 hover:bg-colorPrimary hover:text-white ${filter === item?.name && " bg-colorPrimary text-white  border-colorPrimary"} gap-y-2`}>     
                                            {item?.name}
                                        </button>
                        
                    
                    )
                    }
                </div>
                <hr className='w-full my-2' />
                <div className='flex flex-wrap mt-3 gap-x-2 gap-y-2'>
                {
                    chapterLoading?
                //     <div className="w-44 h-24 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-lg border-2">
                //     <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
                //     <div className="w-24 h-4 bg-gray-300 mt-2 rounded-full"></div>
                // </div>


                <div className='flex flex-wrap justify-between gap-x-2'>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                <div className="w-44 h-8 animate-pulse border border-gray-200 flex flex-col items-center justify-center rounded-full border-2 ">
                    <div className="w-24 h-2 bg-gray-300  rounded-full"></div>
                </div>
                                                    </div>
                
                
                :
                       chapters && chapters.general?.map((item) =>
                        // <button onClick={() => getPrompts(item)} className={`border border-colorPrimary p-5  text-colorPrimary flex flex-col items-center justify-center rounded-lg border-2 hover:bg-colorPrimaryHover ${filter === item?.name && "bg-colorPrimaryHover"} gap-y-2`}>
                        //     <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //         <circle cx="12.5" cy="12" r="11.25" stroke="#0067AD" stroke-width="1.5" />
                        //         <circle cx="7.94814" cy="8.27627" r="1.65517" fill="#0067AD" />
                        //         <circle cx="17.0517" cy="8.27627" r="1.65517" fill="#0067AD" />
                        //         <path d="M18.9484 13.6553C19.5007 13.6553 19.9554 14.1054 19.8821 14.6528C19.3946 18.2947 16.2753 21.1035 12.5 21.1035C8.72473 21.1035 5.60545 18.2947 5.11798 14.6528C5.04471 14.1054 5.49957 13.6553 6.05186 13.6553C9.41442 13.6553 12.5 13.6553 12.5 13.6553C12.5 13.6553 15.8612 13.6553 18.9484 13.6553Z" fill="#0067AD" />
                        //     </svg>
                        //     {item?.name}
                        // </button>
                        <button onClick={() => {getPrompts(item)}} className={`border border-gray-300 px-5 py-2  text-gray-500 flex flex-col items-center justify-center rounded-full border-2 hover:bg-colorPrimary hover:text-white ${filter === item?.name && " bg-colorPrimary text-white  border-colorPrimary"} gap-y-2`}>     
                                            {item?.name}
                                        </button>
                        
                    
                    )
                    }
                </div>
            </div>
            {
                promptLoading?<div className="flex flex-col lg:flex-row sm:justify-between items-center bg-gray-100 rounded-xl mt-2 py-2 px-5 animate-pulse">
                <div className="w-full lg:w-full h-8 sm:h-10 flex flex-col gap-y-1 ">
                    <div className="h-full bg-gray-300 rounded-full" ></div>
                    <div className="h-full bg-gray-300 rounded-full w-1/2" ></div>
                    
                </div>
                <div className="w-full lg:w-1/2 h-full lg:h-20 lg:ml-4 flex items-center justify-center sm:justify-end">
                <div className="h-10 w-10 bg-gray-300 rounded-full mt-2"></div>
                   
                </div>
            </div>
            :
                (
                    (data)?
                    <div>
                        <div className='flex justify-between items-center'>
                        <h3 className='font-semibold'>Prompt</h3>
                        <h3 className='font-semibold'>{addedPrompts}/{total_prompt}</h3>
                        </div>
                
                <div className="flex flex-col lg:flex-row sm:justify-between items-center bg-gray-100 rounded-xl mt-2 py-4 px-5">
                    
                    <p className='text-lg text-justify'>
        <Typewriter
          words={[data?.prompt]}
          key={data?.prompt}
          loop={1}  // Change to `Infinity` for infinite looping
          typeSpeed={20}
          deleteSpeed={10}
          delaySpeed={300}
        />
      </p>

                    {/* <AudioRecorder/> */}
                    <RecordingAudio setData={setData} audioUrl={audioUrl} setAudioUrl={setAudioUrl} data={data} />
                </div>
                {
                    audioUrl&&
                    <div id="audios" className='my-4 flex flex-row gap-3'>
                    <audio controls src={audioUrl}></audio>
                    {/* <button className='bg-gray-200 px-4 text-sms rounded-full hover:bg-gray-300' >
                        Delete
                    </button> */}
                    </div>
                }
                
                <div className="mb-2 pt-3 flex gap-3 justify-end mt-4">
                    {
                         loading?<img className='w-9' src={loader} alt="" />:
                         ( audioUrl&&
                            <>
                            <button
                          type="button"
                          // onClick={handleSubmit}
                          onClick={()=>{setAudioUrl(null);setData({...data, audio:null})}}
                          className="bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      >
                          Cancel
                      </button>
                      
                         
                          <button
                          type="button"
                          onClick={handleSubmit}
                          className="relative text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                      >Submit  
                      </button>
                           </>
                         )
                   
                    }
                   
                    

                </div>
                    </div>
                    :
                    <h1>There is no prompt available for this expression please select other</h1>
            )
            }
            
        </div>
    )
}

export default RecordingPage