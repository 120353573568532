import { all } from "redux-saga/effects";
import watchAuth from "../redux/auth";
import { watchUploadFile } from "../redux/uploadFile";
import watchUser from "../redux/user";
import watchLanguage from "../redux/language";
import watchAudio from "../redux/audio";
import watchVideo from "../redux/video";
export default function* rootSagas() {
    yield all([
        watchAuth(),
        watchUploadFile(),
        watchUser(),
        watchLanguage(),
        watchAudio(),
        watchVideo()
    ])
}