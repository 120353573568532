import React from 'react'
import LearningGuide from './LearningGuide'
import Templates from './Templates'
import Avatars from './Avatars'
import TtsSelectLanguage from './TtsSelectLanguage'

const Home = () => {
    return (
        <>
            <div className="px-6 py-6 rounded-lg flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <h1 className='font-semibold text-xl'>Home</h1>
                    {/* <button className='bg-colorPrimary text-white px-5 py-2 rounded-lg text-sm'>Create My First Twin</button> */}
                </div>
                <LearningGuide />
                <TtsSelectLanguage />
                {/* <Templates /> */}
                {/* <Avatars /> */}
            </div>
        </>
    )
}

export default Home