import React, {useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import upload_cloud from '../../../assets/svgs/upload_cloud.svg'
import { uploadFile } from '../../../redux/uploadFile/action';
import Webcam from 'react-webcam';

const ImageModal = ({ data,setData}) => {

    const [showWebcam, setShowWebcam] = useState(false);
    const webcamRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);

    const openWebcam = () => {
        setShowWebcam(true);
    };

    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc);
        setShowWebcam(false);
       
    };

    const retakeImage = () => {
        setCapturedImage(null);
        setShowWebcam(true);
    };

    const saveImage = () => {
        if (capturedImage) {
             setWebCam(false)
             setShowModal(false)
            // Convert the base64 image data to a Blob object
            const blob = dataURLtoBlob(capturedImage);
            // Create a new File object from the Blob
            const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
            // Dispatch the uploadFile action with the file
            dispatch(uploadFile(file));
        }
    };

    const dataURLtoBlob = (dataURL) => {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };
    const fileRef = useRef();
    const file=useSelector((state)=>state?.uploadFileReducer?.uploadFile?.data)
    const dispatch=useDispatch()

    useEffect(() => {
     if(file)
        setShowModal(false);
     setCapturedImage(null);
     setWebCam(false);
        {
            console.log("dnsandlkjsandsadsadsadsadlkndsalkndsandlksad",file);
            setData(prevState => ({
                ...prevState,
                profile_picture: file
            }))
            
        }
    }, [file])
    useEffect(() => {
      console.log("Data in image modal ",data)
    }, [data])
    
    
    // useEffect(() => {
    //     console.log(studio);
    //     if(studio){
    //         navigate("recording")
    //     }
    // }, [studio])
    const handleFile = (e) => {
        const file = e.target.files[0];
        dispatch(uploadFile(file));

      }
    const [showModal, setShowModal] = useState(false);
    const [webcam, setWebCam] = useState(false);
    const handleSubmit = () => {
        setShowModal(false);
    }
    return (
        <>
            {
                    data?.profile_picture?
                    <div onClick={() => setShowModal(true)} className="relative sm:w-[120px] w-[100px] sm:h-[120px] h-[100px] bg-gray-100 border-6 border border-gray-300 rounded-full flex flex-col justify-center items-center gap-1.5 cursor-pointer group">
                        <img className='rounded-full sm:w-[120px] w-[100px] sm:h-[120px] h-[100px]' src={(data?.profile_picture)&&(data?.profile_picture)} alt="upload_cloud" />
     
                        <div className='rounded-full p-1  absolute -right-1 -bottom-1 bg-gray-200'>
                        <svg class="w-8 h-8 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z" clip-rule="evenodd"/>
</svg>
                        </div>
                        <input type="file" name="selfie" id="selfie" className='sr-only' ref={fileRef} onChange={handleFile} />
                    </div>
                    :
                    <div onClick={() => setShowModal(true)} className="sm:w-[120px] w-[100px] sm:h-[120px] h-[100px] relative bg-gray-100 border-6 border border-gray-300 rounded-full flex flex-col justify-center items-center gap-1.5 cursor-pointer">
                                   <div className='rounded-full p-1  absolute -right-1 -bottom-1 bg-gray-200'>
                        <svg class="w-8 h-8 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z" clip-rule="evenodd"/>
</svg>
                        </div>

                        <p className='text-gray-600 text-xs'>Upload a Photo</p>
                        <input type="file" name="selfie" id="selfie" className='sr-only' ref={fileRef} onChange={handleFile} />
                    </div>
                }
            {showModal ? (
                webcam?
                <>
                <div
                    className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative md:w-1/2 w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                <button
                                    className="border-2 px-[5px] border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent text-yellowPrimary float-right text-2xl leading-none font-semibol outline-none focus:outline-none"
                                    onClick={() => {setShowModal(false);setWebCam(false);setCapturedImage(null)}}
                                >
                                    ×
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 pb-5 pt-0 flex-auto">
                               {/* Add here image upload model */}
                               <div className="flex gap-4 justify-center mb-3">
                                
                               <div className="mb-2 pt-3 flex gap-3 justify-center mt-4">
                                {capturedImage? 
                                 <div>
                                <img src={capturedImage} alt="Captured" />
                                <div className='flex  mt-2 gap-2'>
                                 <button className='bg-colorPrimary px-4 py-1 text-white w-full rounded-md cursor-pointer' onClick={saveImage}>Save</button>
                                 <button className='bg-white border-colorPrimary border-2 text-colorPrimary px-4 py-1 w-full rounded-md cursor-pointer' onClick={retakeImage}>Retake</button>
                                 </div>
                                 </div>
                                : <div>
                                <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                className="webcam"
                                
                            /> 
                            <div className="flex justify-center mt-2">
                                <button className='bg-colorPrimary px-4 py-1 text-white w-full rounded-md cursor-pointer' onClick={captureImage}>Capture</button>
                              
                            </div></div>  }
                                </div> 
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
                :
                <>
                    <div
                        className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative md:w-1/2 w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                    <button
                                        className="border-2 px-[5px] border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent text-yellowPrimary float-right text-2xl leading-none font-semibol outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        ×
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 pb-5 pt-0 flex-auto">
                                   {/* Add here image upload model */}
                                   <div className="flex gap-4 justify-center mb-3">
                    <div  onClick={()=>{setWebCam(true);openWebcam()}} className="sm:w-[120px] w-[100px] sm:h-[120px] h-[100px] bg-gray-100 border-6 border border-gray-300 rounded-full flex flex-col justify-center items-center gap-2 cursor-pointer">
                        <p>
                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23 17C23 17.5304 22.7893 18.0391 22.4142 18.4142C22.0391 18.7893 21.5304 19 21 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H7L9 1H15L17 4H21C21.5304 4 22.0391 4.21071 22.4142 4.58579C22.7893 4.96086 23 5.46957 23 6V17Z" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15Z" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
                        </p>
                        <p className='text-gray-600 text-xs'>Take a Photo</p>
                    </div>
                    <div onClick={() => fileRef.current.click()} className="sm:w-[120px] w-[100px] sm:h-[120px] h-[100px] bg-gray-100 border-6 border border-gray-300 rounded-full flex flex-col justify-center items-center gap-1.5 cursor-pointer">
                        <img src={upload_cloud} alt="upload_cloud" />
                        <p className='text-gray-600 text-xs'>Upload a Photo</p>
                        <input type="file" name="selfie" id="selfie" className='sr-only' ref={fileRef} onChange={handleFile} />
                    </div>
                </div>          
                                    <div className="mb-2 pt-3 flex gap-3 justify-center mt-4">
                                         <button
                                         className="text-white w-full bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                         type="button"
                                         onClick={handleSubmit}
                                     >
                                         Next
                                     </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }
        </>
    )
}

export default ImageModal