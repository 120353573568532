import { call, put, takeLatest } from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from "react-toastify";
import { GET_CLONE_LANGUAGE, GET_CLONE_LANGUAGE_FAILURE, GET_CLONE_LANGUAGE_SUCCESS, GET_LANGUAGE, GET_LANGUAGE_FAILURE, GET_LANGUAGE_SUCCESS } from "../actonTypes";
function* watcherGetLanguage() {
    let url='/Language_controller/languageGet';
    const Data = yield call(callApi, url, 'GET','',true);
    // console.log(Data.data)
    console.log("..................................",Data.data)
    if (Data.status===200) {
        console.log("Data.data",Data.data)
        yield put({type : GET_LANGUAGE_SUCCESS,payload:Data.data});
        // toast.success(Data.data.message)
        console.log("..................................",Data.data)
    }
    else{
        yield put({type:GET_LANGUAGE_FAILURE,payload:Data.data.error})
    }
}

function* watcherGetCloneLanguage() {
    let url='/Language_controller/cloneLanguageGet';
    const Data = yield call(callApi, url, 'GET','',true);
    // console.log(Data.data)
    console.log("..................................",Data.data)
    if (Data.status===200) {
        console.log("Data.data",Data.data)
        yield put({type : GET_CLONE_LANGUAGE_SUCCESS,payload:Data.data});
        // toast.success(Data.data.message)
        console.log("..................................",Data.data)
    }
    else{
        yield put({type:GET_CLONE_LANGUAGE_FAILURE,payload:Data.data.error})
    }
}
export default function* watchLanguage() {
    yield takeLatest(GET_LANGUAGE,watcherGetLanguage)
    yield takeLatest(GET_CLONE_LANGUAGE,watcherGetCloneLanguage)
}