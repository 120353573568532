import React, { useEffect, useState } from 'react'
import RegisterForm from "./RegisterForm";
// import logo from '../../../assets/svgs/logo.svg'
import logo from '../../../assets/svgs/dipspik.svg'
import VerificationPhone from './VerificationPhone';
import OtpScreen from '../../../components/ui/OtpScreen';
export default function Signup() {
  const [screenStep, setScreenStep] = useState("registration")
  return (
    <div className="flex flex-col lg:justify-betwee justify-cente w-full h-screen">
      <div>
        <img src={logo} alt="logo" className="ml-6 my-5 h-fit" />
      </div>
      {screenStep === 'registration' && <RegisterForm setScreenStep={setScreenStep} />}
      {screenStep === 'verify' && <VerificationPhone setScreenStep={setScreenStep} />}
      {screenStep === 'otp' && <OtpScreen setScreenStep={setScreenStep} />}
    </div>
  )
}
