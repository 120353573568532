import {CREATE_AUDIO_STUDIO_FAILURE, CREATE_AUDIO_STUDIO_SUCCESS, CREATE_AUDIO_STUDIO, GET_CHAPTER, GET_CHAPTER_SUCCESS, GET_CHAPTER_FAILURE, GET_CHAPTER_PROMPT, GET_CHAPTER_PROMPT_SUCCESS, GET_CHAPTER_PROMPT_FAILURE, GET_AUDIO_STUDIO, GET_AUDIO_STUDIO_SUCCESS, GET_AUDIO_STUDIO_FAILURE, SET_CHAPTER_PROMPT, SET_CHAPTER_PROMPT_SUCCESS, SET_CHAPTER_PROMPT_FAILURE, GET_RECORDINGS, GET_RECORDINGS_SUCCESS, GET_RECORDINGS_FAILURE, GET_VIDEO_PROMPT, GET_VIDEO_PROMPT_SUCCESS, GET_VIDEO_PROMPT_FAILURE, SET_VIDEO_PROMPT, SET_VIDEO_PROMPT_SUCCESS, SET_VIDEO_PROMPT_FAILURE, CLEAR_VIDEO_PROMPT } from "../actonTypes";
import { getRecordings, setChapterPrompt } from "./action";
const initial_state = {
    // createAudioStudio:{
    //     data:"",
    //     message: "",
    //     error: "",
    //     loading: false,
    // },
    // getChapter:{
    //     data:"",
    //     message: "",
    //     error: "",
    //     loading: false,
    // },
    // getAudioStudio:{
    //     data:"",
    //     message: "",
    //     error: "",
    //     loading: false,
    // },
    // getRecordings:{
    //     data:"",
    //     message: "",
    //     error: "",
    //     loading: false,
    // },
    getVideoPrompt:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
    setVideoPrompt:{
        data:"",
        message: "",
        error: "",
        loading: false,
    },
}
const videoReducer = (state = initial_state, { type, payload }) => {
    console.log("payload",payload)
    switch (type) {
        // case CREATE_AUDIO_STUDIO:
        //     return {
        //         ...state,
        //         createAudioStudio: {
        //             ...state.createAudioStudio,
        //             loading: true,
        //         }
        //     };
        // case CREATE_AUDIO_STUDIO_SUCCESS:
        //     console.log("GET_USER_DETAILS_SUCCESS",payload.data)
        //     return {
        //         ...state,
        //         createAudioStudio: {
        //             ...state.createAudioStudio,
        //             loading: false,
        //             message: payload.message,
        //             data:payload.data,
        //             error: null
        //         }
        //     };
        // case CREATE_AUDIO_STUDIO_FAILURE:
        //     return {
        //         ...state,
        //         createAudioStudio: {
        //             ...state.createAudioStudio,
        //             loading: false,
        //             error: payload,
        //         }
        //     };
        
        // case GET_AUDIO_STUDIO:
        //     return {
        //         ...state,
        //         getAudioStudio: {
        //             ...state.getAudioStudio,
        //             loading: true,
        //         }
        //     };
        // case GET_AUDIO_STUDIO_SUCCESS:
        //     console.log("GET_AUDIO_STUDIO_SUCCESS",payload.data)
        //     return {
        //         ...state,
        //         getAudioStudio: {
        //             ...state.getAudioStudio,
        //             loading: false,
        //             message: payload.message,
        //             data:payload.data,
        //             error: null
        //         }
        //     };
        // case GET_AUDIO_STUDIO_FAILURE:
        //     return {
        //         ...state,
        //         getAudioStudio: {
        //             ...state.getAudioStudio,
        //             loading: false,
        //             error: payload,
        //         }
        //     };
        
        // case GET_CHAPTER:
        //     return {
        //         ...state,
        //         getChapter: {
        //             ...state.getChapter,
        //             loading: true,
        //         }
        //     };
        // case GET_CHAPTER_SUCCESS:
        //     console.log("GET_USER_DETAILS_SUCCESS",payload.data)
        //     return {
        //         ...state,
        //         getChapter: {
        //             ...state.getChapter,
        //             loading: false,
        //             message: payload.message,
        //             data:payload.data,
        //             error: null
        //         }
        //     };
        // case GET_CHAPTER_FAILURE:
        //     return {
        //         ...state,
        //         getChapter: {
        //             ...state.getChapter,
        //             loading: false,
        //             error: payload,
        //         }
        //     };
        
        case GET_VIDEO_PROMPT:
            return {
                ...state,
                getVideoPrompt: {
                    ...state.getVideoPrompt,
                    loading: true,
                }
            };
        case GET_VIDEO_PROMPT_SUCCESS:
            console.log("GET_VIDEO_PROMPT_SUCCESS",payload.data)
            return {
                ...state,
                getVideoPrompt: {
                    ...state.getVideoPrompt,
                    loading: false,
                    message: payload.message,
                    data:payload.data,
                    error: null
                }
            };
        case GET_VIDEO_PROMPT_FAILURE:
            return {
                ...state,
                getVideoPrompt: {
                    ...state.getVideoPrompt,
                    loading: false,
                    error: payload,
                }
            };    
    
        case SET_VIDEO_PROMPT:
                return {
                    ...state,
                    setVideoPrompt: {
                        ...state.setVideoPrompt,
                        loading: true,
                    }
                };    
        case SET_VIDEO_PROMPT_SUCCESS:
                console.log("GET_USER_DETAILS_SUCCESS",payload.data)
                return {
                    ...state,
                    setVideoPrompt: {
                        ...state.setVideoPrompt,
                        loading: false,
                        message: payload.message,
                        data:payload.data,
                        error: null
                    }
                };    
        case SET_VIDEO_PROMPT_FAILURE:
                return {
                    ...state,
                    setVideoPrompt: {
                        ...state.setVideoPrompt,
                        loading: false,
                        error: payload,
                    }
                };       
                case CLEAR_VIDEO_PROMPT:
                return {
                    ...state,
                    setVideoPrompt: {
                        ...state.setVideoPrompt,
                        loading: false,
                        message:null,
                    }
                };  
        

        //          case GET_RECORDINGS:
        //     return {
        //         ...state,
        //         getRecordings: {
        //             ...state.getRecordings,
        //             loading: true,
        //         }
        //     };
        // case GET_RECORDINGS_SUCCESS:
        //     console.log("GET_AUDIO_RECORDINGS_SUCCESS",payload.data)
        //     return {
        //         ...state,
        //         getRecordings: {
        //             ...state.getRecordings,
        //             loading: false,
        //             message: payload.message,
        //             data:payload.data,
        //             error: null
        //         }
        //     };
        // case GET_RECORDINGS_FAILURE:
        //     return {
        //         ...state,
        //         getRecordings: {
        //             ...state.getRecordings,
        //             loading: false,
        //             error: payload,
        //         }
        //     };
        
        default:
                return state;
        }
}
export default videoReducer
       