import React, { useEffect, useState } from 'react'
import TutorialVideo from './TutorialVideo';
import UploadVideoOptions from './UploadVideoOptions';
import { AVATAR, FEEDBACK, RECORD, TUTORIAL, UPLOAD, UPLOADED, UPLOAD_OPTIONS } from '../../../constants/VideoModalStepper';
import UploadVideo from './UploadVideo';
import arrowSvg from '../../../assets/svgs/arrowWithCircle.svg'
import RecordVideo from './RecordVideo';
import VideoUploaded from './VideoUploaded';
import SelectAvatar from './SelectAvatar';
import Feedback from './Feedback';
import { useSelector } from 'react-redux';

const CreateRecordingModal = () => {
    const [language, setLanguage] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [stepType, setStepType] = useState(TUTORIAL);
    const videoPrompt = useSelector((state) => state?.videoReducer?.setVideoPrompt?.message);
    const handleCloseModal = () => {
        setLanguage(null);
        setStepType(TUTORIAL);
        setShowModal(false);
    }

    useEffect(() => {
        if (videoPrompt) {
            handleCloseModal()
            console.log(videoPrompt);
        }
    }, [videoPrompt])

    return (
        <>
            <button onClick={() => setShowModal(true)} className='flex items-center justify-between gap-1.5 bg-colorPrimary text-white px-4 py-2 rounded-lg text-sm ml-auto'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.22595 9.11167C9.22595 10.2742 8.43379 11.0956 7.16679 11.0956H3.54079C2.27495 11.0956 1.48804 10.2742 1.48804 9.11225V5.05167C1.48804 3.88908 2.27554 3.0625 3.5472 3.0625H7.16737C8.43437 3.0625 9.22654 3.88908 9.22654 5.05167V9.11167H9.22595Z" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" /><path d="M9.22607 6.57794L11.616 4.62144C11.9952 4.30935 12.5709 4.5806 12.5709 5.07469V9.08335C12.5709 9.57802 11.9952 9.84752 11.616 9.5366L9.22607 7.5801" stroke="white" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" /></svg>
                <span>Create Video</span>
            </button>
            {showModal ? (
                <>
                    <div
                        className="justify-center rounded-3xl items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative md:w-1/2 w-[95%] my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                {stepType !== UPLOADED ? <div className="flex items-center rounded-t-3xl justify-center pt-5 mx-5 pb-3">
                                    <button
                                        className="text-gray-800 float-right leading-none outline-none focus:outline-none flex items-center gap-1"
                                        onClick={handleCloseModal}
                                    >
                                        <img src={arrowSvg} alt="arrow" />
                                        <span className='font-bold text-sm'>Back</span>
                                    </button>
                                    <button
                                        className="border-[1.5px] px-[3px] py-1 border-gray-800 text-gray-800 rounded-full ml-auto bg-transparent float-right text-xl leading-[0.5] outline-none focus:outline-none"
                                        onClick={handleCloseModal}
                                    >
                                        ×
                                    </button>
                                </div> : <div className='mt-6'></div>}
                                {/*body*/}
                                {stepType === TUTORIAL && <TutorialVideo setStepType={setStepType} />}
                                {stepType === UPLOAD_OPTIONS && <UploadVideoOptions setStepType={setStepType} setLanguage={setLanguage} language={language} />}
                                {stepType === UPLOAD && <UploadVideo setStepType={setStepType} stepType={stepType} language={language} />}
                                {stepType === RECORD && <RecordVideo setStepType={setStepType} stepType={stepType} language={language} />}
                                {stepType === UPLOADED && <VideoUploaded setStepType={setStepType} stepType={stepType} />}
                                {stepType === AVATAR && <SelectAvatar setStepType={setStepType} stepType={stepType} />}
                                {stepType === FEEDBACK && <Feedback setShowModal={setShowModal} setStepType={setStepType} stepType={stepType} />}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }
        </>
    )
}

export default CreateRecordingModal