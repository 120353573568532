import React, { useState } from 'react'
import SettingSideBar from './SettingSideBar';
import UpdateUserProfile from './UpdateUserProfile';
import PaymentSettings from './PaymentSettings';

const Settings = () => {
    const [settingsType, setSettingsType] = useState(1)
    return (
        <>
            <div className="px-6 py-6 rounded-lg flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <h1 className='font-semibold text-xl'>Settings</h1>
                </div>
                <div className="profileSettingLayout grid grid-rows-1 grid-cols-1 lg:grid-cols-5 gap-y-8 lg:gap-8">
                    <div className="sideBar md:block lg:min-w-40 w-full rounded-lg bg-gray-50 shadow">
                        <SettingSideBar setSettingsType={setSettingsType} settingsType={settingsType} />
                    </div>
                    {settingsType === 1 && <UpdateUserProfile />}
                    {/* {settingsType === 2 && <PaymentSettings />} */}
                </div>
            </div>
        </>
    )
}

export default Settings