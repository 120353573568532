import React from 'react'
import { RECORD, UPLOAD } from '../../../constants/VideoModalStepper'

const ButtonsVideoTypeSelect = ({ setStepType, stepType }) => {
    return (
        <>
            <div className="mb-5 flex gap-3 justify-center">
                <button type="button" onClick={() => setStepType(UPLOAD)} className={` text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${stepType === UPLOAD ? "text-white bg-colorPrimary" : "bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary"}`}>Upload Footage</button>
                <button type="button" onClick={() => setStepType(RECORD)} className={`text-sm border border-colorPrimary font-medium px-5 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${stepType === RECORD ? "text-white bg-colorPrimary" : "bg-white hover:bg-colorPrimary  hover:text-white text-colorPrimary"}`}>Record With Webcam</button>
            </div>
        </>
    )
}

export default ButtonsVideoTypeSelect