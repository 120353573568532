import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import SettingSideBar from './SettingSideBar'
import ttsIcon from '../../../assets/svgs/startTtsicon.svg'
import loadingTtsicon from '../../../assets/svgs/loadingTtsicon.svg'
import BackArrow from '../../../assets/jsx/BackArrow'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGeneratedVoices, textToSpeech } from '../../../redux/audio/action'
const decodeURL = (url) => {
    return decodeURIComponent(url);
};
const Tts = () => {
    const loading=useSelector((state)=>state.audioReducer?.textToSpeech?.loading)
  
    const {id,name}=useParams();
const dispatch=useDispatch()
    const navigate = useNavigate()
    const [text, setText] = useState("")
    const [script, setScript] = useState("")
    const [audio, setAudio] = useState(false)
    // const [loading, setLoading] = useState(false)


    // const handleResponse = () => {
    //     setLoading(false)
    //     setAudio(true)
    // }


    const handleSubmit = () => {
        if(text){
            dispatch(textToSpeech({
                language_id:id,
                prompt:text
            }))
            
        }
        if (!text) return;
        setAudio(false)
        // setLoading(true)
        setScript(text)
        setText("")
        // setTimeout(handleResponse, 5000)
    }
    const handleClear = () => {
        setAudio(false)
        // setLoading(false)
        setScript("")
        setText("")
    }
    return (
        <div className='h-full'>
            <div className="px-6 py-6 rounded-lg flex flex-col gap-6 bg-gray-50 h-full">
                <div className="flex justify-between items-center bg-gray-100 px-4 py-3 rounded-lg">
                    <h1 onClick={() => navigate(-1)} className='flex gap-3 items-center font-semibold text-xl cursor-pointer [&>svg]:w-6'>
                        <BackArrow />
                        <span className='text-colorPrimary'>{name}</span>
                    </h1>
                </div>
                <div className="grid grid-rows-1 grid-cols-1 lg:grid-cols-6 gap-3 lg:h-[82vh]">
                    <div className="sideBar md:block lg:col-span-2 w-full shadow py-4 rounded-lg bg-white overflow-auto">
                        <SettingSideBar handleClear={handleClear}/>
                    </div>
                    <div className="flex flex-col xl:flex-nowrap lg:col-span-4 h-full shadow rounded-lg bg-white relative overflow-auto">
                        <div className="text-end py-4 px-5 border-b">
                            <button className='bg-colorPrimary text-white px-9 py-2 ml-auto rounded-lg text-sm'>Export</button>
                        </div>

                        {/* Script */}
                        <div className={`bg-[#E5F0F7] rounded p-2 m-4 xl:text-sm text-xs ${((loading || audio) && script) ? "block" : "hidden"}`}>{script}</div>

                        {/* Audio */}
                        {/* {audio && <div className="flex gap-2 text-sm justify-end text-gray-400 w-full px-4 mt-2 mb-4">
                            <audio className='w-full' controls src={""} ></audio>
                        </div>} */}

                        {/* Center Image Icon */}
                        <div className={`flex justify-center items-center grow ${audio && "hidden"}`}>
                            <img className={`lg:w-[300px] w-[200px] my-9 mx-5 ${loading && "animate-pulse"}`} src={loading ? loadingTtsicon : ttsIcon} alt="processing" />
                        </div>

                        {/* Textarea */}
                        <div className={`mx-2 my-1 ${audio ? "hidden" : "block"}`}>
                            <p className='text-sm text-colorPrimary text-end'>{text?.length}/2000</p>
                            <form onSubmit={handleSubmit} className="relative">
                                <textarea name="" id="" cols="30" rows="3" value={text} disabled={loading} onChange={(e) => setText(e.target.value)} placeholder='Write Script...' className='w-full p-2 bg-gray-100 rounded-lg border focus:border-colorPrimary outline-none'></textarea>
                                <button type='submit' className={`absolute bottom-0 right-0 m-3 z-20 bg-colorPrimary px-2 py-3 rounded-lg text-white rotate-90 [&>svg]:w-5 ${!text && "hidden"}`}>
                                    <BackArrow />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Tts