import React from 'react'
import InputField from '../../../components/ui/InputField'
import { useDispatch } from 'react-redux';

export default function VerificationPhone({ setScreenStep }) {
    const dispatch=useDispatch()
    return (
        <div className="flex flex-col sm:w-[406px] mx-5 sm:mx-auto grow">
            <div className="flex flex-col gap-3 my-auto">
                <div className="mb-4 text-center">
                    <h1 className="text-colorPrimary text-2xl font-semibold mb-1.5">Welcome!</h1>
                    <p className="text-gray-500 text-sm">Enter your phone number to get started.</p>
                </div>
                <div>
                    {/* <label for="price" class="block text-sm font-medium leading-6 text-gray-900">Enter Phone Number</label> */}
                    <InputField label={"Enter Phone Number"} type={"phone"} />
                    {/* <div class="relative mt-2 rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 flex items-center">
                            <label for="currency" class="sr-only">Currency</label>
                            <select id="currency" name="currency" class="h-full rounded-md border-0 bg-transparent py-0 pr-2 pl-3 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-colorPrimary sm:text-sm">
                                <option>US</option>
                                <option>UK</option>
                                <option>PK</option>
                            </select>
                        </div>
                        <input type="text" name="price" id="price" class="block w-full rounded-md border-0 py-1.5 pr-7 pl-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-colorPrimary sm:text-sm sm:leading-6 outline-none" placeholder="+40" />
                    </div> */}
                </div>
                <div>
                    <button type="submit" onClick={() => {setScreenStep("otp")}} className="flex w-full justify-center rounded-md bg-colorPrimary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-colorPrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-colorPrimary">Continue</button>
                </div>
            </div>
        </div>
    )
}
