import React, { useState } from 'react'
import { FEEDBACK } from '../../../constants/VideoModalStepper'
import thumb1 from '../../../assets/images/thumbnails/thumbnail1.png'
import thumb2 from '../../../assets/images/thumbnails/thumbnail2.png'
import thumb3 from '../../../assets/images/thumbnails/thumbnail3.png'
import thumb4 from '../../../assets/images/thumbnails/thumbnail4.png'
import SelectAvatarItem from './SelectAvatarItem'

const SelectAvatar = ({ setStepType }) => {
    const [setAvatar, setSetAvatar] = useState(null)
    const avatars = [
        {
            thumbnail: thumb1,
            id: 1
        },
        {
            thumbnail: thumb2,
            id: 2
        },
        {
            thumbnail: thumb3,
            id: 3
        },
        {
            thumbnail: thumb4,
            id: 4
        },
        {
            thumbnail: thumb4,
            id: 5
        },
        {
            thumbnail: thumb3,
            id: 6
        }
    ]
    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto">
            <h6 className='font-semibold'>Select Avatar</h6>
            <div className="flx gap-3 my-5 grid grid-cols- gap-x-2 gap-y-2 sm:gap-x-4 sm:gap-y-4 grid-cols-2 lg:grid-cols-3">
                {avatars?.map((avatar, index) => (
                    <SelectAvatarItem avatar={avatar} key={index} setAvatar={setAvatar} setSetAvatar={setSetAvatar} />
                ))}
            </div>
            <div className='text-center'>
                <button type="button" onClick={() => setStepType(FEEDBACK)} className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Continue</button>
            </div>
        </div>
    )
}

export default SelectAvatar