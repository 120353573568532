import React from 'react'
import ThumbLikeSvg from '../../../components/ui/ThumbLikeSvg'

const Feedback = ({ setShowModal }) => {
    const feedback = [
        {
            title: "Voice",
            id: 1
        },
        {
            title: "Facial Expression",
            id: 2
        },
        {
            title: "Body Expression",
            id: 3
        },
        {
            title: "Voice",
            id: 4
        },
        {
            title: "Gestures",
            id: 5
        },
        {
            title: "Overall realism of the video",
            id: 6
        }
    ]
    return (
        <div className="relative p-6 pb-5 pt-0 flex-auto">
            <h6 className='font-semibold my-3'>Feedback</h6>
            <div className="flex flex-col gap-2 mb-3">
                {feedback?.map((avatar, index) => (
                    <div key={index} className='text-colorPrimary bg-[#F0F6FA] flex justify-between items-center px-3 py-2 rounded-lg'>
                        <p className='font-semibold text-sm'>{avatar.title}</p>
                        <div className="flex gap-2">
                            <ThumbLikeSvg like={true} />
                            <ThumbLikeSvg like={false} />
                        </div>
                    </div>
                ))}
            </div>
            <textarea name="comments" id="comments" rows="4" className='border border-gray-400 w-full bg-gray-100 rounded-lg p-2 outline-none focus:border-colorPrimary my-2' placeholder='Write some comments about your experience'></textarea>
            <div className='text-center'>
                <button type="button" onClick={() => setShowModal(false)} className="text-white bg-colorPrimary text-sm border border-colorPrimary font-medium px-7 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">Submit</button>
            </div>
        </div>
    )
}

export default Feedback