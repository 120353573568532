import { EMPTY, UPLOAD_AUDIO, UPLOAD_AUDIO_FAILURE, UPLOAD_AUDIO_SUCCESS, UPLOAD_CSV, UPLOAD_CSV_FAILURE, UPLOAD_CSV_SUCCESS, UPLOAD_FILE, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from "../actonTypes"
import { uploadCSV } from "./action"

const initial_state = {
    uploadFile: {
        message: null,
        error: null,
        loading: false,
    },
    uploadAudio: {
        message: null,
        error: null,
        loading: false,
    },
    uploadCSV: {
        data:null,
        message: null,
        error: null,
        loading: false,
    },
}
const uploadFileReducer = (state = initial_state, { type, payload }) => {
    switch (type) {
        case UPLOAD_FILE:
            return {
                ...state,
                uploadFile: {
                    loading: true,
                }
            }
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadFile: {
                    loading: false,
                    message: null,
                    error: null,
                    data: payload.data
                }

            }

        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                uploadFile: {
                    loading: false,
                    error: payload,
                }

            }
        case UPLOAD_AUDIO:
            return {
                ...state,
                uploadAudio: {
                    loading: true,
                }
            }
        case UPLOAD_AUDIO_SUCCESS:
            return {
                ...state,
                uploadAudio: {
                    loading: false,
                    message: null,
                    error: null,
                    data: payload.data
                }

            }

        case UPLOAD_AUDIO_FAILURE:
            return {
                ...state,
                uploadAudio: {
                    loading: false,
                    error: payload,
                }

            }


            case UPLOAD_CSV:
            return {
                ...state,
                uploadCSV: {
                    loading: true,
                }
            }
        case UPLOAD_CSV_SUCCESS:
            return {
                ...state,
                uploadCSV: {
                    loading: false,
                    message: null,
                    error: null,
                    data: payload.data
                }
            }

        case UPLOAD_CSV_FAILURE:
            return {
                ...state,
                uploadCSV: {
                    loading: false,
                    error: payload,
                }

            }
        case EMPTY:
            return {
                ...state,
                uploadAudio: {
                    data: null,
                }

            }

        default: return { ...state };
    }

}
export default uploadFileReducer