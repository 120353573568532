import React from 'react'
import { useNavigate } from 'react-router'

const VideoItem = ({ thumbnail, title, duration, date }) => {
    const navigate = useNavigate()
    return (
        <>
            <div onClick={() => navigate("editvideo")} className='p-3 rounded-lg border-2 border-gray-300 hover:bg-gray-50 flex flex-col gap-2.5 cursor-pointer mx-2 mb-6'>
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-32 h-48 2xl:h-60">
                    <img src={thumbnail} alt="thumbnail" className='rounded-md h-full w-full object-cover object-center lg:h-full lg:w-full' />
                </div>
                <div className="flex flex-col gap-2">
                    <p className='text-sm font-semibold text-black leading-none'>{title}</p>
                    <div className="flex gap-1.5 text-sm">
                        <p className='text-gray-600'>Duration:</p>
                        <p className='font-semibold'>{duration}</p>
                    </div>
                    <div className="flex gap-1.5 text-sm">
                        <p className='text-gray-600'>Release Date:</p>
                        <p className='font-semibold'>{date}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoItem