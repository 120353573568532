import React from 'react'
import VideoPlaySvg from '../../components/ui/VideoPlaySvg'

const LearningVideoItem = ({ thumbnail, title }) => {
    return (
        <div className='p-2.5 rounded-lg border-2 border-colorPrimary hover:bg-[#EBF3F9] flex flex-col gap-2.5 cursor-pointer'>
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-32 h-48 2xl:h-60">
                <img src={thumbnail} alt="thumbnail" className='rounded-md h-full w-full object-cover object-center lg:h-full lg:w-full' />
            </div>
            <div className="flex items-center gap-2 text-colorPrimary">
                <VideoPlaySvg />
                <p className='text-base font-semibold text-black leading-none'>{title}</p>
            </div>
        </div>
    )
}

export default LearningVideoItem