import {call,put, takeLatest} from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from "react-toastify";
import { All_USER_GET, All_USER_GET_FAILURE, All_USER_GET_SUCCESS, GET_USER, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, OTP_VERIFICATION, OTP_VERIFICATION_FAILURE, OTP_VERIFICATION_SUCCESS, RESEND_OTP, RESEND_OTP_FAILURE, RESEND_OTP_RESET, RESEND_OTP_SUCCESS, SIGNUP, SIGNUP_FAILURE, SIGNUP_SUCCESS, SOCIAL_SIGNUP_LOGIN, SOCIAL_SIGNUP_LOGIN_FAILURE, SOCIAL_SIGNUP_LOGIN_SUCCESS, TESTING_AUTH_TOKEN, TESTING_AUTH_TOKEN_FAILURE, TESTING_AUTH_TOKEN_SUCCESS } from "../actonTypes";
import { callTestingApi } from "../../api/TestingAPIs";
function* watcherLogin(data) {
    let url='/Auth_controller/login';
    const Data = yield call(callApi, url, 'POST',data.payload);
    console.log(Data);
    if(Data.status===200){
        toast.success(Data.data.message)
        yield put({type : LOGIN_SUCCESS,payload:Data.data});
        
           
    }
    else{
        yield put({type:LOGIN_FAILURE,payload:Data.data})
        toast.error(Data?.data?.message)
    }
}
function* watcherAllUserGet() {
    console.log("index is hitting")
    let url='/Testing_controller/allUserGet';
    // const base='https:/dipspik.com/soulcast';
    const Data = yield call(callTestingApi, url, 'GET','');
    console.log(Data);
    if(Data.status===200){
        console.log(`Data...................${Data?.data}`)
        // toast.success(Data.data.message)
        yield put({type : All_USER_GET_SUCCESS,payload:Data.data});
        
           
    }
    else{
        yield put({type:All_USER_GET_FAILURE,payload:Data.data})
        toast.error(Data?.data?.message)
    }
}
function* watcherTestingAuthToken(data) {
    let url='/Testing_controller/getAuthToken';
    const Data = yield call(callTestingApi, url, 'POST',data.payload);
    console.log(Data);
    if(Data.status===200){
        // toast.success(Data.data.message)
        yield put({type : TESTING_AUTH_TOKEN_SUCCESS,payload:Data.data});  
    }
    else{
        yield put({type:TESTING_AUTH_TOKEN_FAILURE,payload:Data.data})
        toast.error(Data?.data?.message)
    }
}
function* watcherSocialSignupLogin(data) {
    let url='/Auth_controller/social_login';
    const Data = yield call(callApi, url, 'POST',data.payload);
    console.log(Data);
    if(Data.status===200){
        // toast.success(Data.data.message)
        yield put({type : SOCIAL_SIGNUP_LOGIN_SUCCESS,payload:Data.data});
            {
                yield put({type:GET_USER})                                                                                                                                                                                                                                                                                                                                                                                                                             
            }
    }
    else{
        toast.error(Data?.data?.message)
        console.log("dsasasasasasasasasasasasasasasasasasasasasasasasasasasasa------------------Social",Data.data);
        yield put({type:SOCIAL_SIGNUP_LOGIN_FAILURE})
    }
}
function* watcherSignup(data) {
    let url='/Auth_controller/signUp';
    const Data = yield call(callApi, url, 'POST',data.payload);
    if (Data.status === 200) {
        yield put({type : SIGNUP_SUCCESS,payload:Data.data});
    }
    else{
        console.log(Data.data.message);
        yield put({type:SIGNUP_FAILURE,payload:Data.data})
        console.log(Data.data);
        toast.error(Data?.data?.message)
        

    }
}
function* watcherOtpVerification(data) {
    let url='/Auth_controller/otpVerify';
    const Data = yield call(callApi, url, 'POST',data.payload);
    if (Data.status === 200) {
        yield put({type : OTP_VERIFICATION_SUCCESS,payload:Data.data});
        localStorage.setItem('authToken',Data.data.token)
        yield put({type : GET_USER});
    }
    else{
        yield put({type:OTP_VERIFICATION_FAILURE,payload:Data.data.message})
        toast.error(Data?.data?.message)
    }
}

function* watcherResendOtp(data) {
    
    let url='/Auth_controller/resendOtp';
    const Data = yield call(callApi, url, 'POST',data.payload);
    if (Data.status === 200) {
        yield put({ type: RESEND_OTP_RESET});
        toast.success(Data?.data?.message)
        yield put({type : RESEND_OTP_SUCCESS,payload:Data.data});
        // localStorage.setItem('authToken',Data.data.token)
        // yield put({type : GET_USER});
    }
    else{
        yield put({type:RESEND_OTP_FAILURE,payload:Data.data.message})
        toast.error(Data?.data?.message)
    }
}
export default function* watchAuth() {
    yield takeLatest(LOGIN,watcherLogin)
    yield takeLatest(SIGNUP,watcherSignup)
    yield takeLatest(OTP_VERIFICATION,watcherOtpVerification)
    yield takeLatest(RESEND_OTP,watcherResendOtp)
    yield takeLatest(SOCIAL_SIGNUP_LOGIN,watcherSocialSignupLogin)
    yield takeLatest(TESTING_AUTH_TOKEN,watcherTestingAuthToken)
    yield takeLatest(All_USER_GET,watcherAllUserGet)



    
}
