import React, { useRef } from 'react'
import VideoItem from './VideoItem'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import PreviousNextSlideBtn from '../../components/ui/PreviousNextSlideBtn';
import CreateRecordingModal from './CreateRecordingModal';
import thumb1 from '../../assets/images/thumbnails/thumbnail1.png'
import thumb2 from '../../assets/images/thumbnails/thumbnail2.png'
import thumb3 from '../../assets/images/thumbnails/thumbnail3.png'
import thumb4 from '../../assets/images/thumbnails/thumbnail4.png'

const MyVideos = () => {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        arrows: false,
        slidesPerColumn: 4,
        slidesPerRow: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
        ]
    };
    const videos = [
        {
            thumbnail: thumb1,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb1,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb1,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb1,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb1,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb2,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb2,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb2,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb2,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb2,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb3,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb3,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb3,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb3,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb3,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
        {
            thumbnail: thumb4,
            title: "My Intro for react position",
            duration: "1 Minute 10 Seconds",
            date: "April 14, 2023",
        },
    ]
    return (
        <div>
            <div className="flex justify-between items-center flex-wrap">
                <h1 className='font-semibold text-lg'>My Videos</h1>
                <CreateRecordingModal />
            </div>
            <div className="mt-3 flex justify-between">
                <p className='font-semibold'>Session One</p>
                <p className='font-semibol text-gray-400'>16/800</p>
            </div>
            {videos.length > 0 ?
                <div className="gri md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-3 mt-4">
                    <Slider {...settings} ref={slider => sliderRef = slider}>
                        {videos.map((video, index) => (
                            <VideoItem {...video} key={index} index={index + 1} />
                        ))}
                    </Slider>
                    <div className='mt-3 flex gap-2.5 justify-center'>
                        <PreviousNextSlideBtn handleSlide={previous} next={false} />
                        <PreviousNextSlideBtn handleSlide={next} next={true} />
                    </div>
                </div> :
                <div className="flex flex-col gap-3 text-sm text-center mt-8">
                    <h6 className='font-semibold'>Currently you have not any videos</h6>
                    <div className="flex justify-center gap-1">
                        <h6 className='font-semibold'>Note:</h6>
                        <p>In order to create your digital duplicate. You have to upload 21 Videos of 30 seconds and 10 voice with duration of 2 minutes to 60 minutes</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default MyVideos