import React, { useState } from 'react'
import thumb from '../../assets/images/thumbnails/thumbnail2.png'
import MyVoices from './MyVoices'
import CreateRecordingModal from './CreateRecordingModal';
import { useSelector } from 'react-redux';
const Audios = () => {

    const [expression, setExpression] = useState('');
    const [audio, setAudio] = useState(true)
    const languages = useSelector((state) => state.audioReducer?.getAudioStudio?.data)
    return (
        <>

            <div className="px-6 py-4 rounded-lg flex flex-col gap-2">
            <>
                    <div className="flex justify-between items-center">
                        <h1 className='font-semibold text-xl'>{expression? <span className='text-colorPrimary'>{"Audio Assets for " +expression + " Expression"}</span>: "Audio Assests" }</h1>
                    </div>
                    <div className="flex justify-between items-center flex-wrap bg-gray-100 rounded-xl py-4 px-5 gap-2 mb-1">
                        <div className="flex items-center gap-3 flex-wrap">
                            <img src={thumb} alt="thumbnail" className='rounded-xl md:w-[121px]' />
                            <div>
                                <p className='font-semibold my-1 md:my-0'>Record your Unique Voices</p>
                                <p className='text-gray-600 text-sm'>You are not familiar how to record audio, explore the tutorial guide </p>
                            </div>
                        </div>
                        <div className='ml-auto w-full md:w-auto'>
                            <button className='bg-colorPrimary text-white px-5 py-2 rounded-lg text-sm w-full md:w-auto'>Learn More</button>
                        </div>
                    </div></>
                {
                    audio&&<>
                    <div className="flex justify-between items-center flex-wrap">
                <div>
                    <h1 className='font-semibold text-xl'>My Voices</h1>
                    <h1 className=' text-sm'>Record yourself speaking the text below. You can play it back or re-record before submitting</h1>
                </div>
                {
                    languages?.length>0&&
                    <CreateRecordingModal />
                }
                
                </div>
                <MyVoices setExpression={setExpression} />
                    </>
                }
            </div >
        </>
    )
}

export default Audios