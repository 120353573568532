import { GET_CLONE_LANGUAGE, GET_LANGUAGE} from "../actonTypes"
export const getLanguage= () => {
    return ({
        type: GET_LANGUAGE,
    })
}
export const getCloneLanguage= () => {
    return ({
        type: GET_CLONE_LANGUAGE,
    })
}
