import {CLEAR_VIDEO_PROMPT, GET_VIDEO_PROMPT, SET_VIDEO_PROMPT} from "../actonTypes"

export const getVideoPrompt= (data) => {
    return ({
        type: GET_VIDEO_PROMPT,
        payload:data
    })
}
export const setVideoPrompt= (data) => {
    return ({
        type: SET_VIDEO_PROMPT,
        payload:data
    })
}
export const clearVideoPrompt= (data) => {
    return ({
        type: CLEAR_VIDEO_PROMPT,
        payload:data
    })
}


