import { call, put, takeLatest } from "redux-saga/effects";
import { callApi } from "../../api/APIs";
import { toast } from 'react-toastify'
import { REMOVE_METADATA_CSV, UPLOAD_AUDIO_FAILURE, UPLOAD_AUDIO_SUCCESS, UPLOAD_CSV, UPLOAD_CSV_FAILURE, UPLOAD_CSV_SUCCESS, UPLOAD_FILE, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from "../actonTypes";
function* watcherUploadFile(data) {
    let toastId = toast.loading("Loading...")
    const formData = new FormData();
    formData.append('file', data.payload);
    let url = '/Upload_file/image_upload';
    const Data = yield call(callApi, url, 'POST', formData);
    if (Data.status === 200) {
        toast.update(toastId, { render: Data.data.message, type: 'success', isLoading: false, autoClose: 1000 })
        yield put({ type: UPLOAD_FILE_SUCCESS, payload: Data.data });
    }
    else {
        toast.update(toastId, { render: Data.data.error, type: 'error', isLoading: false, closeButton: true })
        yield put({ type: UPLOAD_FILE_FAILURE, payload: Data.data.error })
    }
}

function* watcherUploadAudio(data) {
    let toastId = toast.loading("Loading...")
    const formData = new FormData();
    formData.append('file', data.payload);
    let url = '/Upload_file/audio_upload';
    const Data = yield call(callApi, url, 'POST', formData);
    if (Data.status === 200) {
        toast.update(toastId, { render: Data.data.message, type: 'success', isLoading: false, autoClose: 1000 })
        yield put({ type: UPLOAD_AUDIO_SUCCESS, payload: Data.data });
    }
    else {
        toast.update(toastId, { render: Data.data.error, type: 'error', isLoading: false, closeButton: true })
        yield put({ type: UPLOAD_AUDIO_FAILURE, payload: Data.data.error })
    }
}

function* watcherUploadCSV(data) {
    let toastId = toast.loading("Loading...")
    const formData = new FormData();
    const file = new File([data.payload.csv], 'audio.csv');
    formData.append('csv', file);
    formData.append('language_id',data.payload.language_id);
    formData.append('chapter_id',data.payload.chapter_id);


    let url = '/Upload_file/csv_upload';
    const Data = yield call(callApi, url, 'POST', formData,true);
    if (Data.status === 200) {
        toast.update(toastId, { render: Data.data.message, type: 'success', isLoading: false, autoClose: 1000 })
        yield put({ type: UPLOAD_CSV_SUCCESS, payload: Data.data });
        yield put({type:REMOVE_METADATA_CSV});
    }
    else {
        toast.update(toastId, { render: Data.data.error, type: 'error', isLoading: false, closeButton: true })
        yield put({ type: UPLOAD_CSV_FAILURE, payload: Data.data.error })
    }
}
export function* watchUploadFile() {
    yield takeLatest(UPLOAD_FILE, watcherUploadFile)
    yield takeLatest(UPLOAD_CSV, watcherUploadCSV)
}
