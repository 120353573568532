import React from 'react'
import TickCheckSvg from '../../../components/ui/TickCheckSvg'

const SelectAvatarItem = ({ avatar, setAvatar, setSetAvatar, }) => {
    return (
        <div onClick={() => setSetAvatar(avatar)} className={`sm:p-2.5 p-1.5 rounded-lg border-2 hover:bg-[#EBF3F9] flex flex-col gap-2.5 cursor-pointer ${setAvatar?.id === avatar.id ? 'border-colorPrimary text-colorPrimary' : 'border-gray-200 text-white'}`}>
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-none group-hover:opacity-75 h-24 2xl:h-36 relative">
                <div className={`absolute top-0 right-0 m-2`}>
                    <TickCheckSvg />
                </div>
                <img src={avatar.thumbnail} alt="thumbnail" className='rounded-md h-full w-full object-cover object-center lg:h-full lg:w-full' />
            </div>
        </div>
    )
}

export default SelectAvatarItem