import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { PublicRoute } from "./PublicRoutes";
import Signup from "../pages/auth/Signup/index";
import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import Layout from "../components/layout";
import Audios from "../pages/Audios";
import RecordingPage from "../pages/Audios/RecordingPage";
import Videos from "../pages/Videos";
import EditVideo from "../pages/Videos/EditVideo";
import Settings from "../pages/Settings";
import Tts from "../pages/Home/Tts";
import Admin from "../pages/auth/admin";

const Routers = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          {/* Admin Panel Routes */}
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PublicRoute>
                <Admin />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="/home"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/home/tts/:id/:name"
            element={
              <Layout>
                <Tts />
              </Layout>
            }
          />
          <Route
            path="/audio"
            element={
              <Layout>
                <Audios />
              </Layout>
            }
          />
          <Route
            path="/audio/:id/:name"
            element={
              <Layout>
                <Audios />
              </Layout>
            }
          />
          <Route
            path="/audio/:id/:name/recording/:filterprm/:filterId"
            element={
              <Layout>
                <RecordingPage />
              </Layout>
            }
          />
          <Route
            path="*"
            element={<Navigate to="/audio" replace />}
          />
          <Route
            path="/video"
            element={
              <Layout>
                <Videos />
              </Layout>
            }
          />
          <Route
            path="/video/editvideo"
            element={
              <Layout>
                <EditVideo />
              </Layout>
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          {/* <Route
            path="/personaldetails"
            element={
              <PublicRoute>
                <SignupDetailsStepper />
              </PublicRoute>
            }
          /> */}
          {/* <Route
            path="/"
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard/>
              </Layout>
            }
          />
          <Route
            path="/leaderboard"
            element={
              <Layout>
                <Leaderboard/>
              </Layout>
            }
          />
          <Route
            path="/challenges"
            element={
              <Layout>
                <Challenges>
                  <MainPage/>
                </Challenges>
                  
              </Layout>
            }
          />
          <Route
            path="/challenges/challengepage"
            element={
              <Layout>
                <Challenges>
                  <ChallengePage/>
                </Challenges>
                  
              </Layout>
            }
          />
          <Route
            path="/profilesetting"
            element={
              <Layout>
                <ProfileSetting/>
              </Layout>
            }
          /> */}

          {/* <Route path="/admin/" element={<PublicRoute />} >
            <Route exact path='login' element={<Login />} />
            <Route exact path='signup' element={<Signup />} />
          </Route> */}
        </Routes>
      </HashRouter>
    </>
  );
};

export default Routers;
